<template>
  <t-dropdown :text="ddTitle1 + ' ▼'">
    <div slot-scope="{ hide }" v-if="list">
      <div v-for="(option, index) in newOptions" :key="index" class="py-1 rounded-md shadow-xs">
        <a @click="changeName(option), hide()" class="block px-4 py-2 text-sm cursor-pointer leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100" role="menuitem">
          {{ option }}
        </a>
      </div>
    </div>

    <div slot-scope="{ hide }" v-else>
      <div class="py-2 px-4 rounded-md shadow-xs grid grid-cols-1 gap-3">
        <a @click="hide" class="cursor-pointer text-right">x</a>
        <t-input variant="payslipAmount" placeholder="Enter Amount"></t-input>
        <t-button variant="payslipAmount" @click="hide">Filter</t-button>
      </div>
    </div>
  </t-dropdown>
</template>

<script>
export default {
  props: ["title", "options", "list"],
  data() {
    return {
      ddTitle1: this.title,
      ddTitle2: this.title,
      ddOptions: this.options
    };
  },
  computed: {
    newOptions(){
      return [this.ddTitle2, ...this.ddOptions]
    }
  },
  methods: {
    changeName(option) {
      this.ddTitle1 = option;
    },
  },
};
</script>

<style></style>

<template>
    <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-6 ">
        <div class="bg-transparent">
            <div class="document_skeleton"></div>
        </div>
        <div class="bg-transparent">
            <div class="document_skeleton"></div>
        </div>
        <div class="bg-transparent">
            <div class="document_skeleton"></div>
        </div>
        <div class="bg-transparent">
            <div class="document_skeleton"></div>
        </div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>
<template>

    <div class="migrant-dashboard  lg:grid grid-cols-3 gap-8">
      <div class="lg:col-span-2 mb-8 md:mt-0 2xl:mt-12 ">
        <router-link :to="{name: 'migrant-documents'}">
        <div v-if="migrant.compliance_score >= 0 " class=" transition rounded-xl flex justify-between relative mb-8" :class="'comp-' + migrant.compliance_score">
            <div class="text-container md:w-4/6 2xl:w-3/6 my-8 sm:my-14 mx-8 2xl:mx-16">
                <h2 class="text-white text-4xl font-black pb-4">{{migrant.compliance_score}}% Compliant</h2>
                <p class="text-white">{{ compliance[migrant.compliance_score] }}</p>
            </div>
            <div class="svg-container w-1/6 hidden md:block">
                <img src="@/assets/images/dashboard/compliance-img.svg" class="absolute md:w-40 2xl:w-60  -bottom-0 right-14" alt="Compliance SVG Image">
            </div>
        </div>
        <div v-else>
          <div class="box-loading w-full h-60"  ></div>
        </div>
      </router-link>
        

        
        <div class="visa-info grid grid-cols-1 lg:grid-cols-3 gap-4 mb-8  ">
            
            <info-box title="Visa Type" :value="migrant.visa ? migrant.visa.visa_type : 'None' "  textClass="text-c1C6EE8" /> 
            <info-box title="SOC Code" :value="migrant.employment ? migrant.employment.soc_code : '-- -- --' "  textClass="text-c1C6EE8" /> 
            <info-box title="Visa Expiration" :value="migrant.visa ? $options.filters.dateFormat(migrant.visa.visa_expiry) : '-- -- ----' "  textClass="text-c1C6EE8" /> 
        </div>

   

    <div class="todos grid grid-cols-1 gap-2" v-if="false">
      <DashboardTodo v-for="(todo, index) in todos" :key="index" :todo="todo" />
    </div>

    <!-- <div class="expiring-documents">
            <t-card variant='holdTableCard' header="Expiring Document(s)">
                <div class="p-3">
                    <t-table
                        :headers="documentsTable.tableHead"
                        :data="documentsTable.tableData"
                        >
                        <template slot="row" slot-scope="props">
                            <tr :class="[props.trClass]">
                                <td :class="props.tdClass">
                                    {{ props.row.description }}
                                </td>
                                <td :class="props.tdClass">
                                    {{ props.row.expiringDate }}
                                </td>
                                <td :class="props.tdClass">
                                    {{ props.row.daysLeft}}
                                </td>
                                <td :class="props.tdClass">
                                    <a href="#" class="cursor-pointer text-dashboardblue font-extrabold">{{ props.row.action }}</a>
                                </td>
                            </tr>
                        </template>
                    </t-table>
                </div>
            </t-card>
        </div> -->
  <div v-if="loadingPage">
              <profile-skeleton />
          </div>
    <div v-else class="requestingServices">
      <t-card variant="holdTableCard">
        <!-- For the card header -->
        <template v-slot:header>
          <div class="flex justify-between">
            Requested Services
            <router-link :to="{name: 'migrant-request'}">

            <t-button variant="cardTop" type="button">New Request</t-button>
            </router-link>
          </div>
        </template>

        <!-- Card body holding the table -->
        <div class="p-3">
          
          <div v-if="servicesTable.tableData.length == 0" class=" mt-8 px-8 text-center bg-white rounded-md flex justify-content-center items-center" style="min-height: 60vh">
            <div class="w-full my-10">

            <p>
                You are yet to make any request.
                </p>
                <p>
                    </p> 
                    <div class="my-10 block">
            <router-link :to="{name: 'migrant-request-create'}" class="btn-primary px-4"> Make Request </router-link>
                    </div>
            </div>
        </div>
          <t-table v-if="servicesTable.tableData.length > 0" :headers="servicesTable.tableHead" :data="servicesTable.tableData">
            <template slot="row" slot-scope="props">
              <tr :class="[props.trClass]">
                <td :class="props.tdClass">
                  {{ props.row.id }}
                </td>
                <td :class="props.tdClass">
                  {{ props.row.service_request_type.name }}
                </td>
                <td :class="props.tdClass">
                  {{ props.row.solicitor ? props.row.solicitor.name : 'None'  }}
                </td>
                <td :class="props.tdClass">
                  {{ props.row.created }}
                </td>
                <td :class="props.tdClass">
                  <span class="p-1 rounded-full block text-center px-4" :class="{ completed: props.row.request_status == 'Completed', pending: props.row.request_status == 'Pending', canceled: props.row.request_status == 'Canceled',  ongoing: props.row.request_status == 'Ongoing' }">
                    {{ props.row.request_status }}
                  </span>
                </td>
              </tr>
            </template>
          </t-table>
        </div>
      </t-card>
    </div>
    </div>
    <div class="">
      <articles />
    </div>
 </div>
</template>

<script>
import DashboardTodo from "../../components/DashboardTodo.vue";
import InfoBox from "../../components/infoBox.vue";
import Articles from '../../partials/articles.vue';
import ProfileSkeleton from '../../partials/skeleton/profile-skeleton.vue';
export default {
  props: { migrant: { required: true, type: Object } },

  components: { DashboardTodo, InfoBox, Articles, ProfileSkeleton },
  data() {
    return {
      loadingPage: true,
      compliant: 0,
      compliance: {
        0: 'Fill necessary and upload required document to be fully Tier 2 Compliant',
        20: 'Fill necessary and upload required document to be fully Tier 2 Compliant',
        40: 'Doing great, completely upload remaining required documents to be fully Tier 2 Compliant',
        60: 'Doing great, completely upload remaining required documents to be fully Tier 2 Compliant',
        80: 'You are now almost fully Tier 2 Compliant',
        100: 'You are now fully Tier 2 Compliant',
      },
      visaType: "Business Visa",
      ExpDate: "Mar 17, 2024",
      NextVisaDate: "Feb 14, 2024",
      todos: [
        { title: "ILR request [#0021] initiated by your company. Please fill form.", action: "Fill form" },
        { title: "Admin response to extension request [#0012]", action: "See Response" },
        { title: "ILR request [#0021] initiated by your company. Please fill form.", action: "Fill form" },
      ],
      documentsTable: {
        tableHead: [
          { value: "description", text: "Description" },
          { value: "expiringDate", text: "Expiring Date" },
          { value: "daysLeft", text: "Days Lefts" },
          { value: "action", text: "Action" },
        ],
        tableData: [
          { id: 1, description: "Visa", expiringDate: "May 10, 2021", daysLeft: "655", action: "View Visa" },
          { id: 2, description: "Payslip", expiringDate: "May 10, 2024", daysLeft: "500", action: "View PaySlip" },
          { id: 3, description: "International Passport", expiringDate: "May 10, 2030", daysLeft: "002", action: "View Passport" },
        ],
      },
      servicesTable: {
        tableHead: [
          { value: "id", text: "ID" },
          { value: "description", text: "Description" },
          { value: "solicitor", text: "Solicitor" },
          { value: "date", text: "Date" },
          { value: "status", text: "Status" },
        ],
        tableData: [
         ],
      },
    };
  },
  methods: {
    getServiceRequests(){
      this.loadingPage = true
       this.axios.get('migrant/service-requests').then(response => {
         this.servicesTable.tableData = response.data.data.requests.data
         this.loadingPage = false
        //  this.$emit('loading', false)
      }).catch( err => {
        // this.$emit('loading', false)
        this.$toast.error( err.response.message , {
                                    timeout: 5000
                                });
      })
    }
  },
  mounted(){
    this.getServiceRequests();
    this.$emit('pageTitle', 'Dashboard')
  }
};
</script>

<style></style>

<template>
    <div>
        <div class="w-full flex flex-col justify-center items-center rounded-lg bg-white px-8" style="min-height: 80vh">
                <box-icon name='error' size="lg" color="#DF3939"></box-icon>
            <h2 class="text-2xl font-extrabold">Your extension request was not succesful </h2>
            <p class="my-8 text-c707070">
            This could be as a result of one of the following:
            </p>
            <p class="">
                <ol class="style-type-disc">
                    <li> Poor internet connection </li>
                    <li> Insufficient funds</li>
                    <li> Other issues</li>
                </ol>
            </p>
            <div class="mt-5 mb-4">
            <router-link class="btn-req-o py-5" :to="{name: 'migrant-request'}"> View Request List </router-link>
            <router-link class="btn-req py-3" :to="{name: 'migrant-request-create'}"> Try Again </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                request: "Extension"
            }
        },
        methods: {
            getServiceRequest(){

            }
        }
    }
</script>

<style  scoped>
.btn-req-o{
  @apply bg-white text-c1C6EE8 px-5 py-3 border-c1C6EE8 border mr-4 rounded
}
.btn-req{
  @apply bg-c1C6EE8 px-5 py-3 border-c1C6EE8 border mx-4 text-white rounded transition
}
.btn-req:hover{
  @apply bg-c1C6EE8 px-5 py-3 border-c1C6EE8 border mx-4 text-white rounded opacity-70
}
</style>
import Vue from 'vue'
import App from './App.vue'
import router from './router.js'
import VueFormulate from '@braid/vue-formulate'
import './assets/tailwind.css'
import './assets/fonts/style.css'
import './../public/css/custom.css'
import axios from 'axios'
import VueAxios from 'vue-axios'
import 'boxicons'
import Toast from "vue-toastification";
// Import the CSS or use your own!
import "vue-toastification/dist/index.css";

import { StripePlugin } from '@vue-stripe/vue-stripe';

const options = {
  pk: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
  stripeAccount: "acct_1Jx8GLGeMJTbzbql", //process.env.VUE_APP_STRIPE_ACCOUNT,
  // apiVersion: '2020-08-27',////process.env.VUE_APP_API_VERSION,
  locale: 'en-us'//process.env.VUE_APP_LOCALE,
};


Vue.use(StripePlugin, options);

import './vuetailwind'

Vue.use(Toast, options);
Vue.use(VueFormulate)
Vue.use(VueAxios, axios)

Vue.filter('money', function(val){
    return '£' + val 
})

Vue.filter('dateFormat', function(val){
  var [yyyy, mm, dd] = val.split("-");
  
  return `${dd}/${mm}/${yyyy}`;
})

/** COMPONENTS */

Vue.component('spinner', require('./components/spinner.vue').default)

Vue.axios.defaults.baseURL = process.env.VUE_APP_BASE_URL;
var token = token = localStorage.getItem("apiToken");
Vue.axios.defaults.headers.common = {
  'Authorization': 'Bearer ' + token,
  'Accept': 'application/json'
  // 'X-Requested-With': 'XMLHttpRequest'
}
Vue.axios.defaults.withCredentials = false;

Vue.config.productionTip = false
Vue.config.ignoredElements = [/^ion-/]

new Vue({
  render: h => h(App),
  router
}).$mount('#app')
 
<template>
    <div class="form-preview mt-4 block ">

        <div v-for="form_entry, i in form " :key="i">
 
        <h5>{{ form_entry.label }}</h5>
        <div class="flex justify-between items-center">
        <span>{{ model[form_entry.name] }}</span> <box-icon name="edit" color="#c1c1c1" class="cursor-pointer"  @click="$emit('edit', true)"></box-icon>  
        </div>

        </div>
        
        

    </div>
</template>

<script>
    export default {
        name: 'DocumentFormPreview',
        props: ['model', 'form']
    }
</script>

<style scoped>

.form-preview h5{
    @apply font-extrabold mt-5
}
.form-preview span{
    @apply text-gray-700 text-sm
}


</style>
import Vue from "vue";
import VueTailwind from "vue-tailwind";
import {
  TInput,
  TTextarea,
  TSelect,
  // TRadio,
  TCheckbox,
  TButton,
  // TInputGroup,
  TCard,
  // TAlert,
  // TModal,
  TDropdown,
  // TRichSelect,
  // TPagination,
  // TTag,
  // TRadioGroup,
  // TCheckboxGroup,
  TTable,
  // TDatepicker,
  // TToggle,
  // TDialog,
} from "vue-tailwind/dist/components";
const settings = {
  "t-input": {
    component: TInput,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
      variants: {
        travelHistory: "border block w-full border-gray-200 py-2 border-t-0 border-r-0 border-l-0 placeholder-gray-400 text-gray-400 text-sm ",
        payslipAmount: "border-2 block w-full rounded text-gray-800 py-1 px-2"
      },
    },
  },
  "t-textarea": {
    component: TTextarea,
    props: {
      classes: "border-2 block w-full rounded text-gray-800",
    },
  },
  "t-card": {
    component: TCard,
    props: {
      classes: {
        wrapper: "border-0 block w-full rounded text-gray-800 bg-white shadow-md rounded-t rounded-md ",
        body: "px-8 py-3 grid grid-cols-1 gap-6",
        header: "border-b rounded-t py-4 px-8 font-bold text-lg xl:text-xl text-c242424 bg-cfafafa border-cfafafa",
        footer: "border-t p-3 rounded-b",
      },
      variants: {
        visaCard: {
          body: " py-4 px-5",
          wrapper: "border-0 block min-w-full rounded text-gray-800 bg-white shadow-md",
        },
          articles: {
            wrapper : 'border-0 block w-full rounded-xl text-gray-800 bg-white shadow-md',
          },
          holdTableCard: {
            wrapper : 'border-0 block w-full rounded-lg text-gray-800 bg-white',
            body: 'card-body',
            header: 'border-b rounded-lg py-4 px-5 font-bold text-xl text-c242424 bg-cfafafa border-cfafafa',
            footer: 'border-t p-3 rounded-b'
          },
        absencesPage: {
          wrapper: "border-0 block w-full rounded text-gray-800 bg-white shadow-md",
          body: "px-8 py-3 grid grid-cols-1 gap-6",
          header: "border-b rounded-t py-4 px-3 md:px-8 font-bold text-xl text-c242424 bg-cfafafa border-cfafafa",
          footer: "border-t p-3 rounded-b",
        },
        noHeaderBg: {
          wrapper: "border-0 block w-full rounded text-gray-800 bg-white shadow-md",
          body: "px-8 py-3 grid grid-cols-1 gap-6 overflow-x-auto",
          header: "border-b rounded-t py-6 px-8 font-bold text-xl text-c242424 bg-white border-cfafafa",
          footer: "border-t p-3 rounded-b",

        },
        noPadding: {
          wrapper: "border-0 block w-full rounded text-gray-800 bg-white shadow-md",
          body: "px-0 py-3 grid grid-cols-1 gap-6 overflow-x-auto",
          header: "border-b rounded-t py-6 px-8 font-bold text-xl text-c242424 bg-white border-cfafafa",
          footer: "border-t p-3 rounded-b",

        }
      },
    },
  },
    'dup-table': {
      component: TTable,
      props: {
        classes: {
          table: 'min-w-full',
          thead: '',
          theadTr: '',
          theadTh: 'px-3 py-2 font-semibold text-left',
          tbody: 'bg-white',
          tr: 'text-sm',
          td: 'px-3 py-2 whitespace-no-wrap',
          tfoot: '',
          tfootTr: '',
          tfootTd: ''
        }
      }
  },
  "btn-outline": {
    component: TButton,
    props: {
      classes: "bg-transparent px-4 py-2 border border-c1C6EE8 text-c1C6EE8 font-bold rounded",
      
    },

  },
  "t-button": {
    component: TButton,
    props: {
      variants: {
        cardTop: "px-4 py-1 rounded-full border  border-c1C6EE8 text-sm bg-white hover:bg-cEAEAF3 text-c1C6EE8 text-center",
        noRounded: "block px-4 py-2 text-white bg-blue-500 border border-transparent shadow-sm hover:bg-blue-600",
        payslipAmount: "block px-4 py-1 text-white transition duration-100 ease-in-out bg-payslipsFilter border border-transparent rounded shadow-sm focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50"
      },
    },

  },
  "card-top-button": {
    component: TButton,
    props: {
      classes: "px-4 py-1 rounded-full border border-c1C6EE8 text-sm bg-white hover:bg-cEAEAF3 text-c1C6EE8 w-20 text-center",
    },
  },
  "card-bottom-button": {
    component: TButton,
    props: {
      classes: "transition duration-500 ease-in-out transform  hover:scale-102 px-4 py-1 rounded-full border border-c1C6EE8 text-sm bg-c1C6EE8 hover:bg-c1C6EE8 text-white w-20 text-center disabled:opacity-50 ",
    },
  },
  
  
  "t-table": {
    component: TTable,
    props: {
      classes: {
        table: "table-responsive",
        thead: "",
        theadTr: "",
        theadTh: "px-3 py-2 font-semibold text-left",
        tbody: "bg-white",
        tr: "text-sm",
        td: "px-3 py-2 whitespace-no-wrap",
        tfoot: "",
        tfootTr: "",
        tfootTd: "",
      },
    },
    variants: {
      payslips: {
        table: "min-w-full",
        thead: "",
        theadTr: "",
        theadTh: "px-3 py-2 font-semibold text-left",
        tbody: "bg-white",
        tr: "",
        td: "px-1 py-2 whitespace-no-wrap",
        tfoot: "",
        tfootTr: "",
        tfootTd: "",

      }
    }
  },
  "custom-table": {
    component: TTable,
    props: {
      classes: {
        table: "min-w-full divide-y border-0 bg-white",
        thead: "",
        theadTr: "",
        theadTh: "px-8 py-3.5 font-semibold text-left",
        tbody: "bg-white divide-y border-none ",
        tr: "border-none cursor-pointer even:bg-cF6F6F6",
        td: "px-8 py-3.5 whitespace-no-wrap text-sm",
        tfoot: "",
        tfootTr: "",
        tfootTd: "",
      },
    },
  },
  "table-absence": {
    component: TTable,
    props: {
      classes: {
        table: "min-w-full",
        thead: "bg-gray-50",
        theadTr: "",
        theadTh: "px-3 py-2 text-left text-c707070 text-base",
        tbody: "bg-white",
        tr: "text-sm",
        td: "px-3 py-2 whitespace-no-wrap text-sm",
        tfoot: "",
        tfootTr: "",
        tfootTd: "",
      },
    },
  },
  "t-select": {
    component: TSelect,
    props: {
      fixedClasses: "block text-xs pl-2 pr-3 py-1 transition duration-100 ease-in-out border rounded shadow-sm focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50 disabled:opacity-50 disabled:cursor-not-allowed",
      classes: "text-black placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500 ",
      variants: {
        firstSelect: "text-black w-4/12 md:w-2/12 placeholder-gray-400 bg-white border-gray-300 focus:border-blue-500",
      },
    },
  },
  "select-two": {
    component: TSelect,
    props: {
      wrapped: true,
      classes: {
        wrapper: 'relative',
        input: 'block w-full py-2 text-black text-xs font-bold appearance-none placeholder-gray-400 transition duration-100 ease-in-out bg-white bg-none focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed',
        arrowWrapper: 'pointer-events-none absolute inset-y-0 right-0 bottom-0 left-0 px-1 flex items-center',
        arrow: 'fill-current text-xs'
      }
    }
  },
  "t-checkbox": {
    component: TCheckbox,
    props: {

    }
  },
  't-dropdown': {
    component: TDropdown,
    props: {
      fixedClasses: {
        button: 'flex items-center text-sm font-black block py-2 transition duration-100 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed',
        wrapper: 'inline-flex flex-col',
        dropdownWrapper: 'relative z-10',
        enterClass: 'opacity-0 scale-95',
        enterActiveClass: 'transition transform ease-out duration-100',
        enterToClass: 'opacity-100 scale-100',
        leaveClass: 'opacity-100 scale-100',
        leaveActiveClass: 'transition transform ease-in duration-75',
        leaveToClass: 'opacity-0 scale-95'
      },
      classes: {
        button: 'bg-transparent',
        dropdown: 'origin-top-left absolute left-0 w-56 rounded shadow mt-1 bg-white'
      },
      variants: {
        bulk: {
          button: 'border border-gray-200 px-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          dropdown: 'origin-top-left absolute left-0 w-56 rounded shadow mt-1 bg-white',
        },
        profile: {
          button: 'border border-gray-200 px-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          dropdown: 'origin-top-right absolute right-0 w-40 rounded shadow mt-1 bg-white'
        },
        notif: {
          button: 'border border-gray-200 px-3 focus:border-blue-500 focus:ring-2 focus:ring-blue-500 focus:outline-none focus:ring-opacity-50',
          dropdown: 'origin-top-right absolute right-0 w-80 rounded shadow mt-1 bg-white'
        },
      },
    },
  }
}

Vue.use(VueTailwind, settings);

<template>
  <t-dropdown variant="bulk" :text="ddTitle1 + ' ▼'">
    <div slot-scope="{ hide }">
      <div v-for="(option, index) in ddOptions" :key="index" class="py-1 rounded-md shadow-xs">
        <a @click="hide()" class="flex items-center px-4 py-2 text-sm cursor-pointer leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100" role="menuitem">
          <ion-icon class="text-dashboardblue text-2xl font-bold pr-2" v-if="index === 0" name="mail-outline"></ion-icon>
          <ion-icon class="text-dashboardblue text-2xl font-bold pr-2" v-if="index === 1" name="pricetags-outline"></ion-icon>
          <ion-icon class="text-dashboardblue text-2xl font-bold pr-2" v-if="index === 2" name="newspaper-outline"></ion-icon>
          {{ option }}
        </a>
      </div>
    </div>
  </t-dropdown>
</template>

<script>
export default {
  props: ["title", "options"],
  data() {
    return {
      ddTitle1: this.title,
      ddTitle2: this.title,
      ddOptions: this.options,
    };
  },
};
</script>

<style></style>

<template>
<div>

<div class="bg-white rounded-2xl" v-if="document">

        <FormulateForm
            class="form-build"
            v-model="formInput"
            @submit="saveDocument"
            name="form1"
                >  
    <div class="container">
        <div class="container-body">
            <div class="container-heading">
            <h2> {{ document.name}} </h2>
            </div>

        <div class="">
            <label for="document">

            <div class="drop-area mx-5 my-3 " :class="image ? '' : 'drop-area-droppable'">
            <div v-if="!image && !document_type" class="">

            <div class="drop-area-container">
                   <box-icon name='image-alt' class="h-20 w-20" color="gray" ></box-icon>
                   <br>
                <span> Click or drag and drop document </span>
            </div>
            <h5 class="mt-5 font-extrabold">
                Upload {{document.name}}
            </h5>
            <span>Jpeg, PNG, PDF</span>
        </div>
        <div v-else class=" w-100 sm:max-w-xs xl:max-w-lg mx-auto p-4">
            <img v-show="image" :src="image" alt="" class="shadow-xl w-full transition"> 
            
            <div v-if="document_type" class="w-full h-full bg-gray-100 text-center  ">
                <box-icon type='solid' name='file-pdf' color="rgba(308, 113, 113)" class="w-80 h-80"></box-icon>
                <div class="bg-gray-500 text-white py-2 mx-auto text-center"> {{document.name}} </div>
            </div>

            <div class="mt-10">

            <a href="javascript:void;" class="font-bold" @click="changeDoc"> 
                Change Document 
                </a>
            </div>
        </div>
         
            </div>
            <input type="file" name="" id="document" class="hidden" @change="selectFile">
        </label>
    
        </div>
        

        </div>
        <div class="container-meta rounded-r-xl" :class="(image || document_type) ? 'bg-white' : 'bg-gray-100' ">
            <div class="mx-auto px-4 my-6 max-w-lg w-80">
                <div v-show="!preview">
              <p class="text-c515151 my-5">Upload document before filling form </p>
                <h5 class="font-bold text-lg mb-10">Confirm the following details</h5>
              

                

                <FormulateInput v-for="schemaData,i in schema" :key="i" 
                    :type="schemaData.type"
                    :name="schemaData.name"
                    :options="schemaData.options"
                    :class="schemaData.class"
                    :input-class="schemaData.inputClass"
                    :label-class="schemaData.labelClass"
                    :label="schemaData.label"
                    :validation="schemaData.validation"
                    :validation-name="schemaData.validationName"
                    :disabled="!image && !document_type"
                />
               
            </div>
            <div v-if="preview">
                <document-form-preview :model="formInput" :form="schema" @edit="preview = false"></document-form-preview> 
                <div class="my-4 mt-10 float-right" v-show="document.reminderable && entry && document && editMode">
                    <a href="javascript:void(0)" class=" text-sidebarColor text-sm font-semibold " @click="openModal = true"> Set Document Reminder </a>
                </div>
            </div>
        </div>
     </div> 
        </div>
        <div class="footer flex justify-end " align="right">
            <!-- <router-link v-if="!payslip" :to="{name : 'migrant-documents'}" class="btn-outline" >Close</router-link> -->
            <button @click="$router.go(-1)" class="btn-outline" type="button">Close</button>
            <button  :class=" ((!image && !document_type) || processing || preview) ? 'btn btn-disabled' : 'btn btn-primary' " :disabled="((!image && !document_type) || preview)" type="submit" > 
              
                                    {{ processing ? 'Please Wait...' : 'Save'}}
            </button>
        </div>
        </FormulateForm>
        <div v-if="entry && document && editMode">
            <document-reminder-modal v-show="openModal" :document_file_id="entry.id" :document="document" :document_reminders="entry.reminders" @close="openModal = false" @complete="openModal = false" />
        </div>
        </div>
        <div v-else>
            <profile-skeleton />
            <profile-skeleton />
        </div>
</div>
   
</template>

<script>
import DocumentReminderModal from '../../partials/document-reminder-modal.vue'
import DocumentFormPreview from "../../partials/DocumentFormPreview.vue"
import ProfileSkeleton from '../../partials/skeleton/profile-skeleton.vue'

// import formBuilder from '../../components/FormBuilder.vue'
export default {

  components: {  DocumentFormPreview, ProfileSkeleton, DocumentReminderModal},
        data() {
            return {
                title: 'Document to Upload',
                document_type: false,
                image: null,
                preview: false,
                schema: null,
                openModal: false,
                file: null,
                formInput: {},
                document: null,
                processing: false,
                payslip:false,
                editMode: false,
                entry: null
                   
            }
        },



        methods: {
            selectFile(e){
                this.document_type = false
                this.image = null
                  let file = this.file = e.target.files[0];
                   
                   if((file.size / 1024 / 1024 ) > 1){
                       this.$toast.error( "File size cannot exceed 1MB" , {
                                    timeout: 9000,
                                    position: 'top-center'
                                });
                        this.file = null;
                        return ;   
                   }
  
                var filePath =  e.target.value;

            var allowedExtensions = 
                    /(\.jpg|\.jpeg|\.png|\.gif|\.pdf)$/i;
              
            if (!allowedExtensions.exec(filePath)) {
                alert('This file type is not allowed');
                this.file = null;
                return false;
            }


            var fileExtensions = (allowedExtensions.exec(filePath)); 
           if (file) {
               if(fileExtensions[0] == '.pdf'){
                //    console.log(fileExtensions[0]);
                   this.document_type = true
               }else{
                    this.image = (URL.createObjectURL(file))
               }
            }
        },
            
            changeDoc(){
                this.image = null;
                this.file = null;
                this.document_type = null;
            },
            isCompletelyFilled(){
                (this.schema).forEach(element => {
                
                    if(typeof this.formInput[element.name] == 'undefined'){
                        console.log('failed =' + element.name);
                        return false;
                    }
                    return true;
                });
               
            },
            getDocumentSpecs(){
                this.axios.get('document?slug='+this.$route.params.slug).then(res => {
                    this.document = (res.data.data.document)
                    this.schema = JSON.parse(res.data.data.document.form)
                    if(this.$route.params.slug == 'salary-payslip'){
                        this.payslip = true;
                        return true;
                    }
                    this.entry = res.data.data.entry
                    let formInput = res.data.data.entry.fields
                    // console.log(formInput);
                    if(formInput){
                        this.formInput = (formInput)
                        this.preview = true;
                        this.editMode = true
                    }
                    
                    try {
                        let media = res.data.data.entry.media[0]
                         //SET IMAGE
                        if(media.mime_type == 'image/jpeg' || media.mime_type == 'image/jpg' || media.mime_type == 'image/png' || media.mime_type == 'image/gif'){
                            this.image = media.original_url
                        }else if (media.mime_type =='application/pdf'){
                            this.document_type = true
                        }
                    } catch (error) {
                        console.log(error);
                    }
                    //TODO: Validate FormInput
                    
                    
                   

                    

                }).catch( err => {
                    err
                }) 
            },
            saveDocument(){
                this.processing =true;
                this.preview = false;
                const config = {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                }
                let data = new FormData(); 
                if(this.file != null){
                    data.append('file', this.file);
                }
                data.append('form_input', JSON.stringify(this.formInput) );
                data.append('document_id', this.document.id );
                data.append('new', this.editMode );

                this.axios.post('document', data, config).then(response => {
                    this.processing = false
                    this.message = response.data.message
                    this.preview = true
                    this.$toast.success( this.message , {
                                    timeout: 5000
                                });
                }).catch((err) => {
                    this.$toast.error( err.response.data.message , {
                                    timeout: 5000
                                });
                    this.processing = false
                    this.message = "Operation Failed"
                })
            }
        },
        watch: {
            
        },
        mounted(){
            this.getDocumentSpecs()
            
        }
    }
</script>

<style lang="css" scoped>
.container {
    @apply  sm:flex border ;  
  

}
.container-body{
    @apply sm:w-3/5
}
.container-meta{
    @apply sm:w-2/5 border-l  transition
}
.container-heading{
    @apply font-extrabold text-lg px-10 py-5 2xl:py-10 2xl:px-20
}
.drop-area{
    @apply items-center text-center cursor-pointer transition
}
.drop-area, .container-meta{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50vh;
    
}
.drop-area-droppable:hover{
     @apply mx-10 2xl:mx-20;
    border: 3px dotted #707070;
}

@media (min-width: 640px) {
        .container-meta, .drop-area {
            min-height: 60vh;
        };
    }

.footer{
    @apply  py-5 px-7 
}

.btn-primary{
    color: #fff;
    @apply px-5 py-3 rounded bg-sidebarColor;

}
</style>
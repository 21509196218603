<template>
  <div class="py-8 grid grid-cols-1 gap-5">
    <p class="info text-md font-semibold text-dashboardgray">Can't find answers to your question(s) within our knowledge base? Use the information below to get in touch with us.</p>

    <div class="phone flex justify-start items-start">
      <div class="text-dashboardblue text-2xl mt-1">
        <ion-icon name="call"></ion-icon>
      </div>
      <div class="ml-4">
        <h4 class="font-bold text-base text-c242424">Phone Number</h4>
        <p class="text-dashboardgray text-base">020 3744 2797</p>
      </div>
    </div>
    <div class="whatsapp flex justify-start items-start">
      <div class="text-dashboardblue text-2xl mt-1">
        <ion-icon name="logo-whatsapp"></ion-icon>
      </div>
      <div class="ml-4">
        <h4 class="font-bold text-base text-c242424">Whatsapp</h4>
        <p class="text-dashboardgray text-base">078 9805 9613</p>
      </div>
    </div>
    <div class="email flex justify-start items-start">
      <div class="text-dashboardblue text-2xl mt-1">
        <ion-icon name="mail"></ion-icon>
      </div>
      <div class="ml-4">
        <h4 class="font-bold text-base text-c242424">Email</h4>
        <p class="text-dashboardgray text-base">info@skilledworkerscompliance.com</p>
      </div>
    </div>
    <div class="Address flex justify-start items-start">
      <div class="text-dashboardblue text-2xl mt-1">
        <ion-icon name="location"></ion-icon>
      </div>
      <div class="ml-4">
        <h4 class="font-bold text-base text-c242424">Address</h4>
        <p class="text-dashboardgray text-base">1st Floor, Holborn Gate, 330 High Holborn, London, WC1V 7QT, United Kingdom.</p>
      </div>
    </div>

    <FormulateForm @submit="submitForm" v-model="UserForm" name="contact">
      <h3 class="text-base font-bold">Send us a message</h3>
      <FormulateInput
        type="email"
        name="email"
        label=""
        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
        placeholder="Email"
        validation="required|email"
      />
      <FormulateInput
        type="text"
        name="subject"
        label=""
        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
        placeholder="Subject"
        validation="required"
      />
      <FormulateInput
        type="textarea"
        label=""
        name="message"
        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
        validation="required|max:500,length"
        placeholder="Message"
      />
      <button
        type="submit"
        name="Send Message"
        :disabled="processing"
        :class="processing ? 'btn-disabled' : 'btn-primary' "
        class=""
      > Send Message </button>
    </FormulateForm>
  </div>
</template>

<script>
export default {
  // props: ['user'],
  data() {
    return {
     processing: false,
     UserForm: {}
    };
  },
  methods: {
    submitForm() {
        this.processing = true;
        this.axios.post('contact', this.UserForm).then( res => {
          this.processing = false;
          this.$toast.success( res.data.message , {
                                    timeout: 5000
                                });
          this.$formulate.reset('compliance')
        }).catch(err => {
          this.processing = false;
          this.$toast.error(err.response.data.message, {timeout: 500})
        })
    },
  },
};
</script>

<style></style>

<template>
  <div class="backdrop">
    <div class="modal relative grid grid-cols-1 gap-1">
      <!-- start -->
      <div class="top h-10">
        <h3 class="text-4xl font-extrabold text-dashboardblue">£ {{ activateModal.amount }}</h3>
        <span class="text-dashboardgray text-sm">{{ activateModal.jobTitle }}</span>
      </div>

      <div class="companyName flex justify-between items-center border-t-2">
        <h4 class="w-3/12 font-bold">Company Name</h4>
        <p class="w-8/12">{{ activateModal.compName }}</p>
      </div>
      <div class="dueDate flex justify-between items-center border-t-2">
        <h4 class="w-3/12 font-bold">Due Date</h4>
        <p class="w-8/12">{{ activateModal.dueDate }}</p>
      </div>
      <div class="location flex justify-between items-center border-t-2 ">
        <h4 class="w-3/12 font-bold">Location</h4>
        <p class="w-8/12">{{ activateModal.location }}</p>
      </div>
      <div class="remark flex justify-between items-start border-t-2 py-2">
        <h4 class="w-3/12 font-bold">Remark</h4>
        <div class="w-8/12 grid grid-cols-1 gap-4">
          <p>{{ activateModal.remark }}</p>
          <p>{{ activateModal.remarkBody }}</p>
          <!-- <p> See more <b>salaryguage.com</b></p> -->
        </div>
      </div>


      <div class="flex justify-end bg-gray-200 absolute bottom-0 left-0 right-0 py-5">
        <a class="container w-4/12 text-blue-700 cursor-pointer flex items-center justify-end pr-14" @click="deleteSlip(activateModal.id)">
          <ion-icon name="trash-outline"></ion-icon>
          Delete
        </a>
      </div>
      
      <div class="closeButton absolute -top-2 -right-10 text-4xl text-white cursor-pointer" @click="closeModal()">
        <ion-icon name="close-outline"></ion-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { activateModal: { type: Object } },
  methods: {
    deleteSlip(id) {
      console.log(`This slip has an ID of ${id}: Haaha - Use to make request to server to delete ${id}`);
      // then.... it should close Modal after deleting slip
      this.closeModal();
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
.modal {
  max-width: 600px;
  max-height: 650px;
  width: 80%;
  height: 90%;
  padding: 60px;
  /* margin: auto auto; */
  background: white;
}
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  background: rgba(28, 110, 232, 0.5);
  width: 100%;
  height: 100%;
}
</style>

<template>
    <div v-if="migrant" class="pb-8">
        <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <info-box title="Total" :value="stats.total" textClass="text-c515151 font-extrabold " /> 
            <info-box title="Completed" :value="stats.completed" textClass="text-c12A250 font-extrabold" /> 
            <info-box title="Pending" :value="stats.pending" textClass="text-c1C6EE8 font-extrabold" /> 
            <info-box title="Incomplete" :value="stats.incomplete" textClass="text-cE59824 font-extrabold" /> 

        
        </div>

        <div v-if="loadingPage" class="my-8">
            <profile-skeleton />
        </div>
       
       <div v-else>

        <div v-if="serviceRequests.tableData.length == 0" class=" mt-8 px-8 text-center bg-white rounded-md flex justify-content-center items-center" style="min-height: 60vh">
            <div class="w-full my-10">

            <p>
                You are yet to make any request.
                </p>
                <p>
                A list of all the requests you make will appear here.
                    </p> 
                    <div class="my-10 block">
            <router-link :to="{name: 'migrant-request-create'}" class="btn-primary px-4"> Make Request </router-link>
                    </div>
            </div>
        </div>

          
        <div v-else class="list-payslip-container my-8">
  
      <t-card variant="noPadding">
          
        <template v-slot:header>

          <div class="flex items-center justify-between check">
              <span>Your Requests</span>
            <router-link :to="{name: 'migrant-request-create'}" class="btn-link"> New Request </router-link>

          </div>
          
        </template>

    
        <t-table class="min-width " variant="payslips" :headers="serviceRequests.tableHead" :data="serviceRequests.tableData">
          <template v-slot:thead="props">
            <thead :class="props.theadClass">
              <tr :class="props.trClass" classes="tr-select ">
                
                <th class="text-left text-sm py-3 pl-8">
                  ID <box-icon name='caret-down' class="h-4"></box-icon>
                </th>
                <th class="text-left text-sm py-3 max-w-0">
                 Type <box-icon name='caret-down' class="h-4"></box-icon>
                </th>
                <th class="text-left text-sm py-3">
                Initiator <box-icon name='caret-down' class="h-4"></box-icon>
                  </th>
                <th class="text-left text-sm py-3">
                
                Solicitor 
                 <box-icon name='caret-down' class="h-4" ></box-icon>
                
                </th>
                <th class="text-left text-sm py-3">
                    Cost(£)
                </th>
                <th class="text-left text-sm py-3">
                    Date <box-icon name='caret-down' class="h-4"></box-icon>
                </th>
                <th class="text-left text-sm py-3 max-w-0">
                   Status <box-icon name='caret-down' class="h-4"></box-icon>
                </th>
                <th class="text-left py-3 text-sm">Action(s)</th>
              </tr>
            </thead>
          </template>

          <template slot="row" slot-scope="props">
            <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']" class="py-12 text-xs">
              <td :class="props.tdClass" class="pl-8">
                {{props.row.id}}
              </td>
              <td :class="props.tdClass" class="py-3">
                  {{ props.row.service_request_type.name }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.creator.name  }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.solicitor ?  props.row.solicitor : 'None' }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.service_request_type.cost }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.created }}
              </td>
              <td :class="props.tdClass + ' re-' + props.row.request_status " class="py-3 capitalize">
                {{ props.row.request_status }}
              </td>
              <td :class="props.tdClass" class="py-3 flex items-center justify-evenly">
                <div class="eye w-5/12 cursor-pointer" v-show="props.row.request_status == 'Pending'">
                <router-link :to="{name: 'migrant-request-edit', params: {id: props.row.id}}">
                  <box-icon name='chevron-right-circle' color="#707070"></box-icon>
                </router-link>
                </div>
               
              </td>
            </tr>
          </template>
        </t-table>
      </t-card>
    </div>
       </div>
        
        <div>
           
        </div>
        

    </div>
</template>

<script>
// import Modal from '../../components/Modal.vue'
import infoBox from '../../components/infoBox.vue'
import ProfileSkeleton from '../../partials/skeleton/profile-skeleton.vue'
export default {
        name: 'ServiceRequests',
        props: {migrant: {type: Object, required: true}},
  components: { infoBox, ProfileSkeleton},
        data(){
            return{
              loadingPage: true,
                service_requests: [],
                requestInfo: 'You have new incomplete request form(s) to fill',
                stats: {total: 0, completed: 0, pending: 0, incomplete: 0},
                BulkDD: [ "Message", "Coupon", "Premium article"],
      action: "",
      activateModal: {
        id: null,
        amount: null,
        jobTitle: null,
        compName: null,
        dueDate: null,
        location: null,
        remark: null,
        remarkBody: null
      },
      serviceRequestsHeadDD: {
        companyName: ["Mida Design Ltd", "Morenike & Daughters Ltd.", "David Bloomberg Studios", "Sunday Mba & Sons Ltd"],
        jobTitle: ["Product Designer", "UI/UX", "Graphic Designer"],
        dueDate: ["28/02/2021", "28/02/2021", "28/02/2021", "28/02/2021"],
        remark: ["Underpaid", "Appropiate", "Overpaid"],
      },
      serviceRequests: {
        tableHead: [
                    { value: "ID", text: "Amount" },
                    { value: "Type", text: "Company Name" },
                    { value: "Initiator", text: "Job Title" },
                    { value: "Solicitor", text: "Due Date" },
                    { value: "Cost(€)", text: "Remark All" },
                    { value: "Date", text: "Actions" },
                    { value: "Status", text: "Actions" },
        ],
        tableData: [
          ],
      }     
            }
        },
        methods: {
            getServiceRequests(){
              this.loadingPage = true
                this.axios.get('migrant/service-requests').then(response => {
                  this.loadingPage = false
                    this.serviceRequests.tableData = response.data.data.requests.data
                    this.stats.total = response.data.data.requests.total
                    // this.stats = response.data.stats
                })
            },
             viewSlip(propsRowObj){
                this.activateModal.amount = propsRowObj.amount
                this.activateModal.jobTitle = propsRowObj.jobTitle
                this.activateModal.compName = propsRowObj.companyName
                this.activateModal.dueDate = propsRowObj.dueDate
                this.activateModal.location = propsRowObj.location
                this.activateModal.remark = propsRowObj.remark
                this.activateModal.remarkBody = propsRowObj.remarkBody
                this.activateModal.id = propsRowObj.id
                }
        },
        mounted(){
          this.$emit('pageTitle', 'Service Requests')
          this.getServiceRequests()
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
<div>

    <div class="grid grid-cols-1 sm:grid-cols-2 2xl:grid-cols-3 gap-6 mb-10" v-if="!loadingPage">

      


        <div  class="rounded-lg py-3 px-3 bg-white" v-for="doc,ix in addedDocuments" :key="ix">
            <div class="card-img">
                <img v-if="isImage(doc.file[0])" :src="doc.file[0].original_url" class="h-80 mx-auto"/>
                <div v-if="!isImage(doc.file[0])" class="w-full h-full bg-gray-100 text-center  ">
                <box-icon type='solid' name='file-pdf' color="rgba(308, 113, 113)" class="w-80 h-80"></box-icon>
                </div>
            </div>
            <div class="card-footer flex justify-between my-4">
                <div class="">
                <h4 class="text-lg font-bold"> {{ doc.document.name}}</h4>
                </div>
                <div class="">

                <div class="bg-cF5F5F5  text-center rounded-full py-1 px-1" style="min-width: max-content">
                <a class="bg-transparent mx-1" :href="doc.file[0].original_url" target="_blank">
                    <box-icon name="download" color="#949494"></box-icon>
                </a>
                <button class="bg-transparent mx-1">
                    <box-icon name="timer" color="#949494" @click="setReminder(ix)" class="cursor-pointer" ></box-icon>
                </button>
                <router-link :to="{name: 'migrant-document-upload' , params: {slug: doc.document.slug}}" class="bg-transparent mx-1">
                    <box-icon name="edit" color="#949494" class="hover:font-bold"></box-icon>
                </router-link>
                </div>
                </div>
            </div>
        </div>

        <div class="rounded-lg py-20 px-6 text-center bg-white flex justify-center items-center " v-for="(document,i) in documents" :key="'b-' + i">
            <div>

            <h2 class="text-xl font-bold"> {{document.name}} </h2>
            <div>

            <p class="mt-8 mb-12">
            You are yet to upload this document
            </p>
            </div>


            <router-link :to="{name: 'migrant-document-upload', params: {slug: document.slug}}" class="btn-outline hover:bg-gray-100 transition"> Add Document </router-link>

            </div>
        </div>
</div>
<div v-if="loadingPage">
    <document-skeleton  />
</div>
  <document-reminder-modal v-if="openModal" :document_file_id="selectedDocument.id" :document_reminders="selectedDocument.reminders" :document="selectedDocument.document" @close="openModal = false" @completed="reminderUpdate()"/>
    </div>
</template>

<script>
import DocumentReminderModal from '../../partials/document-reminder-modal.vue';
import documentSkeleton from '../../partials/skeleton/document-skeleton.vue';
    export default {
  components: { documentSkeleton, DocumentReminderModal },
        data () {
            return {
                openModal: false,
                documents: [],
                addedDocuments: [],
                selectedDocument: {},
                loadingPage: true
            }
        },
        methods: {
            isImage(media){
                if(media.mime_type == 'image/jpeg' || media.mime_type == 'image/jpg' || media.mime_type == 'image/png' || media.mime_type == 'image/gif'){
                    return true;
                }else{
                    return false
                }
            },
            setReminder(index){
                this.selectedDocument = this.addedDocuments[index]
                if(this.selectedDocument.document.reminderable == true){
                    this.openModal= true;
                }
            },
            isPDF(media){
                if(media.mime_type == 'document/pdf'){
                    return true;
                }else{
                    return false;
                }
            },
            fetchDocuments(){
                this.loadingPage = true
                this.axios.get('/migrant/get-documents').then(response => {
                    this.loadingPage=false
                    this.documents = (response.data.data.pending)
                    this.addedDocuments = (response.data.data.uploaded)
                })
            },
            reminderUpdate(){
                this.openModal = false
                this.fetchDocuments();
            }
        },
        mounted(){
            this.fetchDocuments();
            this.$emit('pageTitle', 'Documents')
        }
    }
</script>

<style lang="scss" scoped>

</style>
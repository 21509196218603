<template>
    <div>
         <div class="">
        <router-link :to="{ name: 'home' }">
          <div class="logo my-6 mx-6">
            <img src="./../assets/images/logo.svg" alt="logo" width="180.63" height="30" class="hidden xl:block" />
            <img src="./../assets/images/logo-mobile.png" alt="logo" class="md:hidden" style="height: 30px !important; width: 30px !important"  />
          </div>
        </router-link>
        <nav class="mt-20 header overscroll-y-auto">
          <ul class="text-lg">
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link :to="{ name: 'migrant-home' }" class="flex">
                <img src="./../assets/images/dashboard.svg" alt="" class="mr-4 h-8 w-8 xl:h-5 " />
                <span class="hidden xl:block">Dashboard</span>
              </router-link>
            </li>
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link :to="{ name: 'migrant-profile' }" class="flex" >
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 h-8 w-8 xl:h-5 "  viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M10 9a3 3 0 100-6 3 3 0 000 6zm-7 9a7 7 0 1114 0H3z" clip-rule="evenodd" />
                </svg>
                 <span class="hidden xl:block">Profile</span>
              </router-link>
            </li>
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link :to="{name: 'migrant-documents'}" class="flex" :class="pageTitle == 'Documents' ? 'router-link-exact-active router-link-active' : '' ">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 h-8 w-8 xl:h-5" height="28" width="19" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4zm2 6a1 1 0 011-1h6a1 1 0 110 2H7a1 1 0 01-1-1zm1 3a1 1 0 100 2h6a1 1 0 100-2H7z" clip-rule="evenodd" /></svg
                >
                 <span class="hidden xl:block">Documents</span>
              </router-link>
            </li>
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link :to="{ name: 'migrant-request' }" class="flex" :class="pageTitle == 'Requests' ? 'router-link-exact-active router-link-active' : '' ">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 h-8 w-8 xl:h-5" height="28" width="19" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M2 5a2 2 0 012-2h7a2 2 0 012 2v4a2 2 0 01-2 2H9l-3 3v-3H4a2 2 0 01-2-2V5z" />
                  <path d="M15 7v2a4 4 0 01-4 4H9.828l-1.766 1.767c.28.149.599.233.938.233h2l3 3v-3h2a2 2 0 002-2V9a2 2 0 00-2-2h-1z" />
                </svg>
                 <span class="hidden xl:block">Service Requests</span>
              </router-link>
            </li>
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link :to="{ name: 'pay-slips' }" class="flex" :class="pageTitle == 'PaySlips' ? 'router-link-exact-active router-link-active' : '' ">
                <svg xmlns="http://www.w3.org/2000/svg" height="28" class="mr-4 h-8 w-8 xl:h-5" width="19" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clip-rule="evenodd" />
                </svg>
                 <span class="hidden xl:block">Payslips</span>
              </router-link>
            </li>
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link :to="{ name: 'absences-calculator' }" class="flex"
                ><svg xmlns="http://www.w3.org/2000/svg" class="mr-4 h-8 w-8 xl:h-5" height="28" width="19" viewBox="0 0 20 20" fill="currentColor">
                  <path
                    fill-rule="evenodd"
                    d="M6 2a2 2 0 00-2 2v12a2 2 0 002 2h8a2 2 0 002-2V4a2 2 0 00-2-2H6zm1 2a1 1 0 000 2h6a1 1 0 100-2H7zm6 7a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zm-3 3a1 1 0 100 2h.01a1 1 0 100-2H10zm-4 1a1 1 0 011-1h.01a1 1 0 110 2H7a1 1 0 01-1-1zm1-4a1 1 0 100 2h.01a1 1 0 100-2H7zm2 1a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zm4-4a1 1 0 100 2h.01a1 1 0 100-2H13zM9 9a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1zM7 8a1 1 0 000 2h.01a1 1 0 000-2H7z"
                    clip-rule="evenodd"
                  />
                </svg>
                 <span class="hidden xl:block">Absences Calculator</span>
              </router-link>
            </li>
            <li class="my-6 ml-6 text-sidebarTextColor">
              <router-link to="/migrant/help" class="flex">
                <svg xmlns="http://www.w3.org/2000/svg" class="mr-4 h-8 w-8 xl:h-5" height="28" width="19" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
                </svg>
                 <span class="hidden xl:block">Help</span>
              </router-link>
            </li>
          </ul>
        </nav>
      </div>



    </div>
</template>

<script>
    export default {
        name: 'MigrantMenu',
        props: ['pageTitle'],
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div v-if="migrant && service" class="">

                <router-link :to="{name: 'migrant-request'}" class="text-sm text-c1C6EE8 float-right mr-3 mt-2"> 
                    <box-icon name="x"></box-icon>
                       </router-link> 
        <div class="bg-white rounded-xl w-full">
            <div class="text-right">
            </div>
            <div class="md:flex service-request-container ">

                <div class="md:w-1/2">
                <!--heading -->
                <h3 class="text-xl font-extrabold ">{{heading[step-1]}} <a href="#" v-show="step ==2" @click="goBack()"><span class="text-sm float-right  text-c1C6EE8 ">Edit Form</span></a> </h3>
                <!-- end heading -->
                <!-- step- 1 -->
                  
                        <!-- <div class="flex mb-2 justify-between" style="margin-top: 60px">
                            <p class="text-sm text-text tracking-widest "> {{info[step]}} </p>
                            <p class="text-sm text-text font-extrabold"> {{step}} of 2</p>
                        </div> -->
                      
                       <!-- <progress-bar :percentage="step/2 * 100" color="c1C6EE8" class="mb-2"/> -->
  <!-- step- 2 -->
                        <!-- <div class="text-right text-sm text-c989898">Next: Company Details</div> -->
                        <FormulateForm class="mb-10 " v-model="userForm" @submit="previewRequest" :keep-model-data="true">
                                

                                <div v-show="showForm">
                                    <div class="form-input">
                                    <div class="mb-2">
                                    <label class="block">Service Type</label>
                                    </div>

                                <input name="" id="" class="w-full text-gray-400" v-if="service.service_request_type" required readonly="true" :disabled="true" :value="service.service_request_type.name" >
                                    </div>
                                   
                               

                               
                                <FormulateInput
                                type="text"
                                label=""
                                name="visa_duration"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray form-control"
                                placeholder="Length of Visa in Years"
                                validation="required"
                                validation-name="Visa Duration"
                                
                                />

                                <h5 class="font-bold mt-8 mb-4">Required documents</h5>
                                <div class="flex items-center gap-2 text-left" v-show="searchingDocument"> 
                                    <box-icon animation="tada" name='file-find' color="#E59824" class="mr-3"></box-icon>
                                    <span class="text-bold">
                                        Checking Documents ...
                                    </span>
                                </div>
                                <div>
                                    <span class="text-green-500 text-bold" v-show="documentOK">
                                    {{ documentOK }}
                                    </span>
                                    <span class="text-red-400 text-bold" v-show="documentError">
                                    {{ documentError}}
                                    </span>
                                </div>

                                <div>
                                    <div v-for="docs,i in toUpload" :key="i">
                                        <file-input :id="docs.id"  @fileSelected="addFile" :document="docs"/>
                                    </div>
                                </div>
                                

                                
                                <div align="right">

                                <button type="submit"
                                 :class="(!disableContinueBtn) ? 'btn-primary' : 'btn-disabled'"
                                 :disabled ="(disableContinueBtn)"
                                class="mt-8 transition duration-500 ease-in-out text-center py-4 block  text-white w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                > Continue </button>
                                </div>
                                </div>


                                <service-request-edit-preview v-if="!showForm" :images="images" :migrant="migrant" :service="service" :model="userForm" :applicationTypes="applicationTypes" />
                                
                                
                        </FormulateForm>

                        

              






                </div>
                <div class="md:w-1/2 ">
                    <div class="bg-cF5F5F5 block px-6 py-6 pb-20 rounded-xl md:mx-10 mb-10">
                        <p class="text-base text-c242424 font-bold mb-5">Fees</p>
                        <span class="text-c1C6EE8 font-extrabold text-5xl transition"> £{{service.service_request_type.cost}} </span> 
                    <br>
                    <br>
                    <small>Fee does not include VAT</small>
                    </div>

                    <div class="md:mx-10 mb-10" v-show="!showForm"> 

                    <label for="">
                        Select Payment Option
                    </label>
                    
                    <div class="flex items-center my-4 ">
                    <input type="radio" name="payment" v-model="userForm.payment_channel" id="paypal" class="mr-3" value="paypal">
                    <label for="paypal">
                        <img src="@/assets/images/paypal.png" alt="" />
                    </label>
                    
                    <input type="radio" name="payment" v-model="userForm.payment_channel" id="stripe" value="stripe"  class="mx-3"/>
                    <label for="stripe">
                        <img src="@/assets/images/stripe.png" alt="" />
                    </label>
                    </div>
                        {{stripeToken}} 
                    <div v-show="stripeToken">

                        <stripe-checkout :refCode="stripeToken" />

                    </div>
                    
                     <div align="right">

                                <button type="submit" @click="processPayment"
                                :disabled="userForm.payment_channel == null || processing"
                                class="transition mt-12 rounded duration-500 ease-in-out text-center  py-4 block bg-gray-200  border-gray-200  text-white w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                :class="(userForm.payment_channel != null && !processing ) ? 'border-c1C6EE8 bg-c1C6EE8' : ''"
                                > Make Payment </button>
                                </div>
                    </div>
                    
                </div>
                
            </div>

        </div>

    </div>
    <div v-else>
        <profile-skeleton />
        <profile-skeleton />
        <profile-skeleton />

    </div>
</template>

<script>
import FileInput from '../../components/FileInput.vue';
import StripeCheckout from '../../components/StripeCheckout.vue';
import ServiceRequestEditPreview from '../../partials/ServiceRequestEditPreview.vue';

// import ProgressBar from '../../components/ProgressBar.vue'
export default 
{
  components: {FileInput, StripeCheckout, ServiceRequestEditPreview },
  props: ['migrant'],
  name: 'EditServiceRequest',
  data(){
      return{
          step: 1,
          info: ['','SERVICE REQUEST', 'COMPANY DETAILS'],
          heading: ['Edit Service Request', 'Summary'],
          applicationTypes: null,
          showForm: true,
          userForm: {request_index: -1},
          files: [],
          stripe: {},
          service: null,
          stripeToken: '',
          images: [],
          processing: false,
          toUpload: {},
          searchingDocument: false,
          documentOK: false,
          documentError: false,
          disableContinueBtn: true
         
          
      }
  },
  methods: {
      goBack(){
          this.step=1;
        this.showForm=true
      },
      getServiceRequest(){
          this.axios.get('migrant/service-request?id='+ this.$route.params.id).then( (response) => {
            this.service = response.data.data
            this.requiredDocs()
          }).catch(err => {
              err
              //could not fetch application types
          })
      },
      previewRequest(){
          this.showForm = false;
          this.step = 2;
      },
      requiredDocs(){
          //check if user has this document uploaded
          
          this.documentError = this.documentOK = false;
          this.searchingDocument = true
          this.toUpload = []
          this.axios.get('migrant/document-check?id=' + this.service.request_id).then( response => {
            this.searchingDocument = false
            let required_docs = this.service.service_request_type.request_documents
              let uploaded = this.toUpload = response.data.data
            if(uploaded.length > 0){
                var difference = required_docs.filter(obj1 => {
                    return !uploaded.some(obj2 => {
                        return obj1.id == obj2.document_id;
                    })
                })

                if(difference.length > 0){
                   
                    this.disableContinueBtn = true
                    this.documentError = "Documents Incomplete"
                    
                    difference.forEach(element => {
                        this.toUpload.push(element)
                    });

                }else{
                    this.disableContinueBtn = false
                    this.documentOK = "You are all set!"
                }

            }else{
                this.documentError = "Upload the following documents to continue"
                this.toUpload = required_docs
            }

          }).catch( error => {
              this.$toast.error(error.message ? error.message : error.response.data.message , {timeout: 5000, position: 'top-center'})
          })
      },
      addFile(e){
          this.files.push(e.file) 
          this.images.push(e.preview) 
      },
      processPayment(){
          this.processing = true
          this.userForm.company_id = this.migrant.employment.company.id
          this.userForm.request_id = this.applicationTypes[this.userForm.request_index].id
        
          this.axios.post('migrant/complete-request', this.userForm).then( res => {
        //   this.processing = false
                if(this.userForm.payment_channel == 'stripe'){
                    this.stripeToken = res.data.data
                }else{
                            this.$toast.success( res.data.message , {timeout: 2000});
                            setTimeout(() => {
                                window.location = res.data.data
                            }, 2000);
                }   
         }).catch( err => {
             this.$toast.error( err.response.data.message , {timeout: 2000});
            this.processing = false
            // console.log(err);
        })
            
      }
  },
  mounted(){
      this.getServiceRequest();
     
      this.$emit('pageTitle', 'Requests')
  
  }
        
    }
</script>

<style  scoped>
.document_list{
    width: 100%;
    margin-top: 12px;
    margin-bottom: 12px;
    @apply flex;
}
.doc_title{
   @apply align-middle;
   margin: 8px;
}
.doc_thumb{
    /* margin: 12px; */
    height: 40px;
    width: 40px;
    background: #eee;
}
.doc_icon{
    margin: 12px;
}
</style>
<template>
    <div v-if="migrant.user" class="">
        <div class=" mb-8 ">
        <FormulateForm
        @submit="personalInfoHandler"
        v-model="personal_info"
        >
        <t-card
        >
        <template v-slot:header>
            <div class="flex justify-between">
            Personal Details
            <card-top-button type="button" @click="showPersonalForm = !showPersonalForm" >
               {{ showPersonalForm ? 'Cancel' :  'Edit' }} 
            </card-top-button>
            </div>
        </template>
        
        <div class="">
            <sweet-input label="Name" name="name" :readonly="migrant.name == null ? false :true " validation="required" :options="[]" :form="showPersonalForm" :value="migrant.name" />
            <sweet-input label="Email" name="email" :readonly="false" validation="required" :form="showPersonalForm" :value="migrant.user ? migrant.user.email: '' " />
            <div class="flex my-3.5 pb-3" v-show="!showPersonalForm">
                <label for="" class="w-2/6 uppercase my-auto text-sm font-bold text-c515151 tracking-widest min-w-sm ">Password</label>
                <div class="w-4/6">
                    <span class="  " style="font-size: 16px"> XXXXXX </span>
                    <a href='#' @click="openModal()" class="text-c1C6EE8 text-sm ml-5 font-bold"> Change Password </a> 
                </div>
            </div>
            <sweet-input label="Phone Number" name="phone" :readonly="false" validation="required" :form="showPersonalForm" :value="migrant.phone" />
            <!-- <sweet-input label="Nationality" name="nationality" validation="required" :form="showPersonalForm" :value="migrant.country" /> -->
           
            <sweet-input label="Address" name="address" :readonly="false" validation="required" :form="showPersonalForm" :value="migrant.address" /> 
            
            <div class="text-right mt-10 mb-3 ">
            <card-bottom-button type="submit" v-show="showPersonalForm" :disabled="loading">
              Save
            </card-bottom-button>
            </div>
        </div>
        

        </t-card>
        </FormulateForm>
        </div>
        <div class="mb-8">
              <FormulateForm
        @submit="BrpInfoHandler"
        v-model="visa_details"
        >
        <t-card
        >
        <template v-slot:header>
            <div class="flex justify-between">
            Visa Details
            <card-top-button type="button" @click="showBrpForm = !showBrpForm" >
               {{ showBrpForm ? 'Cancel' :  'Edit' }} 
            </card-top-button>
            </div>
        </template>
        
        <div class="">
            <sweet-input label="Visa Type" name="visa_type" :text="migrant.visa ? migrant.visa.visa_type : 'No Visa Record' " validation="required" type="select" 
             :options="[ 'Work Visa' , 'Business Visa','Study Visa','Visitor Visa','Family Visa','Settlement Visa','Transit Visa','None']"
            :form="showBrpForm" 
            :value="migrant.visa ? migrant.visa.visa_type : 'No Visa Record' "  />
           <sweet-input label="Visa Expiration" name="visa_expiry" validation="required" :options="[]" type="date" :form="showBrpForm" :value="migrant.visa ? migrant.visa.visa_expiry : 'None'" />
            <sweet-input label="Visa Duration" name="visa_duration" :text="migrant.visa ? migrant.visa.visa_duration + ' Years' : '0 Year' " validation="required" :form="showBrpForm" :value="migrant.visa ? migrant.visa.visa_duration + ' Yrs' : '0' " type="number" /> 
            
            <div class="text-right mt-10 mb-3 ">
            <card-bottom-button type="submit" class="" v-show="showBrpForm" :disabled="loading">
              Save
            </card-bottom-button>
            </div>
        </div>
        

        </t-card>
        </FormulateForm>
        </div>
        <div class="pb-8">
              <FormulateForm
        @submit="companyInfoHandler"
        v-model="company_form_data"
        >
        <t-card
        >
        <template v-slot:header>
            <div class="flex justify-between">
            Company Details
            <card-top-button type="button" @click="companyForm = !companyForm" >
               {{ companyForm ? 'Cancel' :  'Edit' }} 
            </card-top-button>
            </div>
        </template>
        
        <div class="">
            <sweet-input label="Company Name" name="company_name" validation="required" :options="[]" :form="companyForm" :value="migrant.employment ? migrant.employment.company.company_name : 'None'" />
            <sweet-input label="Employment Date" name="employment_date" type="date" validation="required" :form="companyForm" :value="migrant.employment ? migrant.employment.employment_date : '--/--/--' " />
             <sweet-input label="Job Title" name="job_title" :readonly="false" validation="required" :form="companyForm" :value="migrant.employment ? migrant.employment.job_title : ''" />
            <sweet-input label="SOC Code" name="soc_code" :readonly="false" validation="required" :form="companyForm" :value="migrant.employment ? migrant.employment.soc_code : ''"  /> 
            <div class="text-right mt-10 mb-3 ">
            <card-bottom-button type="submit" class="" v-show="companyForm" :disabled="processing">
              <!-- <spinner v-show="processing" /> -->
              Save
            </card-bottom-button>
            </div>
        </div>
        

        </t-card>
        </FormulateForm>
        </div> 


            <change-password-modal v-if="openPasswordModal"  @close="openModal"/>


        </div>

</template>

<script>
import sweetInput from '../../components/sweetInput.vue'
import ChangePasswordModal from '../../partials/change-password-modal.vue'
    export default {
        name: 'MigrantProfile',
  components: { sweetInput , ChangePasswordModal},
  props: {migrant: {type: Object , required: true}},
        data(){
            return{
                showPersonalForm: false,
                showBrpForm: false,
                companyForm: false,
                personal_info: {},
                company_form_data: {},
                loading: false,
                processing: false,
                visa_details: {},
                openPasswordModal: false,
                
            }
        },
        methods:{
            personalInfoHandler(){
                this.loading = true 

                this.axios.post('migrant/edit-profile-info', this.personal_info).then((response) => {
                    this.$toast.success( "Profile Details Updated Successfully" , {
                                    timeout: 5000
                                });
                    this.showPersonalForm = !this.showPersonalForm
                    this.loading = false;
                    // console.log(response.data.data);
                    this.$emit('migrantDataUpdate', response.data.data);
                }).catch( () => {
                    this.loading = false;

                })
                
            },
            openModal(){
                this.openPasswordModal = !this.openPasswordModal
            },
            BrpInfoHandler(){
                 this.loading = true 

                this.axios.post('migrant/edit-visa', this.visa_details).then((response) => {
                     this.showBrpForm = !this.showBrpForm
                    this.loading = false;
                    // console.log(response.data.data);
                     this.$toast.success( "Visa Details Updated successfully" , {
                                    timeout: 5000
                                });
                    this.$emit('migrantDataUpdate', response.data.data);
                }).catch ( () => {
                    this.loading = false
                })
            },
            companyInfoHandler(){
                this.processing = true;
                this.axios.post('migrant/edit-company-info', this.company_form_data).then((response) => {
                    this.processing = false;
                    this.companyForm = false
                    // this.companyForm = false
                    this.$toast.success( "Employment Information Updated successfully" , {
                                    timeout: 5000
                                });
                    this.$emit('migrantDataUpdate', response.data.data);
                }).catch ( () => {
                    this.processing = false;
                    
                })
                
            }
        },
        mounted(){
        this.$emit('pageTitle', 'Profile')
    }
        
    }
</script>

<style lang="scss" scoped>

</style>
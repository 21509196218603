import Vue from 'vue';
import VueRouter from 'vue-router'

// IMPORT LAYOUTS
import AppLayout from './App.vue'
import CompanyLayout from './layouts/company-layout.vue'
import AdminLayout from './layouts/admin-layout.vue'
import MigrantLayout from './layouts/migrant-layout.vue'


// IMPORT ROUTES COLLECTIONS
import MigrantRoutes from './router/migrant-routes.js'
import CompanyRoutes from './router/company-routes.js'
import AdminRoutes from './router/admin-routes.js'
import DefaultRoutes from './router/default-routes.js'

const checkLoginStatus = (to, from, next) => {
    Vue.axios
      .get('user/')
      .then(() => {
        next()
      })
      .catch(() => {
        next({ name: "pre-login" });
      });
  };

Vue.use(VueRouter);
export default new VueRouter({
    base: '/', 
    routes:[
        { path : '/' , component : AppLayout, children : DefaultRoutes },
        { path : '/migrant/' , component : MigrantLayout, children : MigrantRoutes, beforeEnter: checkLoginStatus },
        { path : '/company/' , component : CompanyLayout, children : CompanyRoutes },
        { path : '/admin/' , component : AdminLayout, children : AdminRoutes }
    ],
    mode: 'history',
});

      
<template>

<div class="lg:flex gap-8">
<div class="lg:w-4/6">

  <TabHelpWrapper>
    <TabHelp title="Knowledge Base">
      <div class="py-8 border-b border-gray-300 grid grid-cols-1 gap-4" v-for="(know, index) in knowledgeBases" :key="index">
        <h4 class="font-bold text-lg text-black">{{know.title}}</h4>
        <p class="text-dashboardgray">{{know.body}}</p>
        <div class="tags text-dashboardgray text-sm">
          <span v-for="(tag, index) in know.tags" :key="index" >{{tag}} | </span> 
        </div>
        <div>
          <t-button variant="cardTop" type="button"><a href="https://immigrationlawyers-london.com/">Continue</a></t-button>
        </div>
        <div class="empty-for-grid"></div>
      </div>
    </TabHelp>
    <TabHelp title="Contact Us">
      <ContactInfo />
    </TabHelp>
  </TabHelpWrapper>
</div>
<div class="lg:w-2/6">
  <articles />
</div>
</div>
</template>

<script>
import TabHelpWrapper from "../../components/TabHelpWrapper.vue";
import TabHelp from "../../components/TabHelp.vue";
import ContactInfo from "../../components/ContactInfo.vue";
import Articles from '../../partials/articles.vue';
export default {
  components: { TabHelpWrapper, TabHelp, ContactInfo, Articles },
  data(){
    return{
      knowledgeBases: [
        { title: "Which Nationals are Exempt from the English Language Requirement?", 
          body: "You are exempt from the English language requirement if you are from one of the following countries:Antigua and Barbuda,Australia,the Bahamas,Barbados,Belize,Canada,Dominica,Grenada,Guyana,Jamaica,Malta,New Zealand,St Kitts and Nevis,St Lucia,St Vincent and the Grenadines,Trinidad and Tobago,USA", 
          tags: ['Mar 12, 2021', 'Companies Search', 'PREMIUM']
        },
        { title: "Can I undertake any extra work?", 
          body: `Yes, it is possible to undertake extra work, should you wish. You can do additional paid work as long as you will still be doing the job which you are being sponsored to do.
        You can work up to 20 hours a week in a job that is either in the same occupation code and at the same skill level as your current job or is a shortage occupation role.
        You can also undertake unpaid work and voluntary roles too.`, 

          tags: ['Mar 12, 2021', 'Companies Search']
        },
        { title: "Can my Family Join me in the UK??", 
          body: "A Skilled Worker can bring visa dependents which include the spouse, unmarried partner and under 18 children.", 
          tags: ['Mar 12, 2021', 'Companies Search']
        },
        { title: "Is there a cooling off period in the Skilled Worker route?", 
          body: "There is no cooling off period under the Skilled Worker route. This means that you can have held a Skilled Worker visa at any point before you apply for fresh entry clearance (make an application from outside the UK) under the route.", 
          tags: ['Mar 12, 2021', 'Companies Search', 'PREMIUM']
        },
        { title: "What Can I Do if I Change Employer?", 
          body: "If you receive a job offer from another UK sponsor, you would need to be issued with a fresh Certificate of Sponsorship, in order to make a “Change of Employment” application. The new skilled worker visa must be granted before you can start sponsored work for the new employer.", 
          tags: ['Mar 12, 2021', 'Companies Search']
        },
        { title: "Do EU nationals need a visa to work in the UK?", 
          body: "After 1 January 2021, except for Irish citizens, all EU nationals coming to work in the UK will need a visa in advance, unless they are eligible to apply for either settled or pre-settled status under the EU Settlement Scheme. To be eligible under the scheme, an individual will need to show they arrived in the UK prior to 11pm 31 December 2020 and apply by 30 June 2021.", 
          tags: ['Mar 12, 2021', 'Companies Search']
        },
        { title: "Who is classed as a skilled worker?", 
          body: "A skilled worker is someone who will be working in a job role in the UK that is deemed to have a skill level of RQF3 or above, (equivalent to A level), as set out under Appendix Skilled Occupations to the Immigration Rules (applicable from 1 December 2020). Jobs are classified in terms of their skill level and content, where workers will not need to hold a formal qualification.", 
          tags: ['Mar 12, 2021', 'Companies Search']
        },
        { title: "Is Tier 2 the same as the skilled worker visa?", 
          body: "The Skilled Worker visa has replaced the Tier 2 (General) visa under the UK’s new single points-based immigration system. The Skilled Worker visa will apply to EEA nationals wanting to come to the UK to work after 1 January 2021, and for non-EEA nationals who apply for entry clearance or permission to stay in the UK on or after 1 December 2020. The new visa is broadly similar to the old visa, although there are some changes aimed at making the process more straightforward for both sponsors and applicants alike.", 
          tags: ['Mar 12, 2021', 'Companies Search']
        },
      ]
    }
  },
  mounted(){
    this.$emit('pageTitle', 'Help')
  }
};
</script>

<style></style>

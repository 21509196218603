<template>
    <div>
        <FormulateForm
            v-model="companyInfo"
            @submit="submitForm" >
                <FormulateInput
                        type="text"
                        label=""
                        name="company_name"
                        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                        placeholder="Company Name"
                        validation="required"
                        
                        />

                        
                        <FormulateInput
                        type="checkbox"
                        name="invite"
                        label="Send Invite to company"
                        placeholder="Send Invite to company"
                        validation=""
                        label-class="ml-4 mt-3 text-text text-sm"
                        input-class="absolute mt-2 "
                        
                        />
                            <FormulateInput
                        type="date"
                        label="Employment Date"
                        name="employment_date"
                        input-class="py-2 mt-5 mb-2  text-right outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                        placeholder="Employment Date"
                        validation="required"
                        label-class="mt-4 text-gray-400 absolute"
                        
                        />
                        <FormulateInput
                        type="text"
                        name="job_title"
                        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                        placeholder="Job Title"
                        validation="required"
                        
                        />
                        <FormulateInput
                        type="text"
                        name="soc_code"
                        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                        placeholder="SOC Code"
                        validation="required|max:4,length|min:4,length"
                        validation-name="SOC Code"
                        

                        />
                        <div class="flex">
                        
                        <button type="button"
                        @click="$emit('decrement', true)"
                        class="transition duration-500 ease-in-out text-center mt-5 bg-white py-4 block border-c1C6EE8 border-2 mr-3 text-primary w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                        > Go Back </button>
                        <button type="submit" :disabled="processing"
                        class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-c1C6EE8 text-white w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                        > 
                        <spinner v-show="processing" />
                        {{ processing ? '' : 'Setup Account' }} 
                        </button>
                        
                        
                        </div>
                        <p class=" mt-4 text-text text-sm">
                            By clicking "Setup account" you agree to our <a href="#" class="text-c1C6EE8 ">Terms and Conditions</a>.
                        </p>
        </FormulateForm>
    </div>
</template>

<script>
    export default {
        props: ['email'],
      
        data(){
            return{
                companyInfo: {},
                processing: false,
            }
        },
         methods: {
            submitForm(){
                this.companyInfo.email = this.email
                this.processing = true
                this.axios.post('migrant/update-company-info', this.companyInfo).then(() => {
                        //redirect to home
                    this.processing = false
                    this.$emit('complete', true)
                    window.location = '/migrant/dashboard'
                }).catch(() => {

                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="form-preview mt-4">
        <h5>Request Type</h5>
        
        <span>{{ applicationTypes[model.request_index].name }}</span>

        
        <h5>Length of Visa (years)</h5>
        <span> {{model.visa_duration}} </span>
        
        <!-- <h5>Required Documents</h5>
        <div class="mt-4" >
            <div v-if="images" class="flex">
            <img v-for="image,index in images" :key="index" :src="image" alt="Document"  class="w-20 mr-8 bg-gray-100" >

            </div> 
        <div class="flex" v-else>
        <div class="block_holder bg-gray-100 mr-4 " style="height: 50px; width: 50px" ></div>
        <div class="block_holder bg-gray-100 mx-4 " style="height: 50px; width: 50px">  </div> 
        </div>
        </div>-->


        <h5>Company Name</h5>
        <span v-if="migrant.employment"> {{migrant.employment.company.company_name}} </span>

        <h5>Email Address</h5>
        <span> {{ migrant.user.email }} </span>


        <h5>Phone Number</h5>
        <span> {{ migrant.phone }} </span>

    </div>
</template>

<script>
    export default {
        name: 'ServiceRequestFormPreview',
        props: ['model', 'migrant', 'images', 'applicationTypes']
    }
</script>

<style scoped>

.form-preview h5{
    @apply font-extrabold mt-5
}
.form-preview span{
    @apply text-gray-700 text-sm
}


</style>
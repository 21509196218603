<template>
    <div>
        
        <div class="flow-root p-5 h-20 w-screen border-b-2 border-solid border-gray-200 lg:px-32 md:px-16">
            <router-link :to="{name: 'home'}">
                <img src="../assets/home_logo.svg" class="float-left lg:h-10 md:h-5 sm:h-5 h-5 sm:my-1.5 my-3.5" alt="">
            </router-link>
            <div class="float-right">
                <div class="flex">

                <ul class="md:flex lg:flex my-2 hidden ">
                    <li class="mr-6">
                        <a class="text-text hover:text-blue-800" href="#">Features</a>
                    </li>
                    <li class="mr-6">
                        <a class="text-text hover:text-blue-800" href="#">Blog</a>
                    </li>
                    <li class="mr-6">
                        <a class="text-text hover:text-blue-800" href="#">Company</a>
                    </li>
                    <li class="mr-6">
                        <a class="text-text hover:text-blue-800" href="#">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
                        </svg>
                        </a>
                    </li>
                
                </ul>
                    <router-link :to="{name: 'pre-login'}" class="border-2 py-1.5 border-sidebarColor text-sidebarColor px-4 sm:px-2 mr-3"> Log in </router-link> 
                    <router-link :to="{name: 'pre-register'}" class="border-2 py-1.5 text-white border-sidebarColor bg-sidebarColor  sm:px-2 px-4"> Sign Up</router-link> 
                </div>
                </div>

        </div>



    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>
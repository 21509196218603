<template>
  <div class="backdrop" v-if="document">
    <div class="modal relative grid grid-cols-1 gap-1 ">
      <!-- start -->
      <div class="" style="max-height: 300px; overflow-y: auto">
       
          <h4 class="text-2xl font-semibold">Set Reminders</h4>
          <p class="my-4">
              Set periodic reminders before the expiry of <b>
                  {{document.name}}
                  </b> 
          </p>
      <FormulateForm
        v-model="reminderForm"
        @submit="submitReminder"
      >
      <div :class="(messageStatus == 'true') ? 'text-green-400 mt-2 font-bold' : 'text-red-500 mt-2 font-bold' ">
          {{message}}
      </div>
      <div class="">

        
        <div class="flex justify-between items-center  border-2 border-t-0 border-l-0 border-r-0 border-b border-gray ">
                                <FormulateInput
                                type="select"
                                name="unit"
                                :options="{1: '1 Month', 2: '2 Months', 3: '3 Months', 4: '4 Months', 5: '5 Months', 6: '6 Months'}"
                                input-class="py-2 mt-5 mb-2 w-full mr-4 outline-none text-text bg-white"
                                class="w-5/6"
                                placeholder="Remind Me in"
                                
                                />
                                <button  type="button" @click="addData()" class="w-1/6 bg-cE1E7EE ml-3 py-3 px-2 -mb-6 font-semibold "> Add </button>
        </div>

                <div class="my-4 ">
                    <span class="bg-cF5F5F5 text-sm rounded-full p-1 m-1 inline-block items-center cursor-pointer " v-for="(reminder,i) in reminders " :key="'x'+i" @click="deleteRecord(i)">
                        {{reminder.unit}} {{reminder.frequency}}
                        <box-icon name="x" size="xs" class="cursor-pointer"></box-icon>
                    </span>
                    <span class="bg-cF5F5F5 text-sm rounded-full p-1 m-1 inline-block items-center cursor-pointer " v-for="(freq,it) in newDates " :key="it" @click="remove(it)">
                        {{freq}} month(s)
                        <box-icon name="x" size="xs" class="cursor-pointer"></box-icon>
                    </span>
                    </div>     
                    <div class=" absolute bottom-5 right-5 left-5 mt-12">
        <button type="submit" :disabled="processing" 
                                :class=" processing ? 'btn-disabled w-full' : 'btn-primary w-full'">
                                 <spinner v-if="processing" />
                                    {{ processing ? '' : 'Save Reminder'}}
                          
                                </button>
                        </div>       
      </div>
      </FormulateForm>
      
      <div class="closeButton absolute -top-2 -right-10 text-2xl text-white cursor-pointer" @click="closeModal()">
       x
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    props:['document', 'document_reminders', 'document_file_id'],
    data(){
        return{
            processing: false,
            reminderForm: {},
            message: null,
            messageStatus: false,
            newDates: []
            
        }
    },
  methods: {
    
    submitReminder(){
      this.message  = this.messageStatus = null
      this.processing  = true;
        this.axios.post('migrant/document/reminder', {reminder: this.newDates, document_file_id: this.document_file_id}).then((response) => {
          //logout
          this.processing  = false;
            this.message = response.data.message
            this.$toast.success(this.message, {timeout: 2000})
            this.messageStatus = response.data.status
            this.$emit('completed', true);

        }).catch((e) => {
          this.$toast.error(e.response.data.message, {timeout: 4000})
            this.processing = false 
            this.messageStatus = 'false'
            this.message = e.response.data.message
        })
    },
    closeModal() {
      this.$emit("close");
    },
    addData(){
        var exists = this.newDates.some(element => {
                        return element == this.reminderForm.unit;
                    })
        if(exists == false){
            this.newDates.push(this.reminderForm.unit)
        }
    },
    deleteRecord(index){
        this.messageStatus = this.message = null
        let reminder = this.reminders[index]
        console.log(reminder);
        this.axios.delete('migrant/document/reminder/'+reminder.id).then( () => {
            this.reminders.splice(index, 1)
        }).catch((e) => {
            this.$toast.error(e.response.data.message, {timeout: 4000})
            this.messageStatus = false
            this.message = 'This reminder could not be deleted'
        })

    },
    remove(ix){
        this.newDates.splice(ix,1)
    }
  },
  computed:{
      reminders() {
          return this.document_reminders
          }
  }
};
</script>

<style scoped>
.modal {
  max-width: 410px;
  min-height: 436px;
  width: 80%;
  /* height: 90%; */
  padding: 45px;
  padding-left: 30px;
  padding-right: 30px;
  /* margin: auto auto; */
  background: white;
}
.backdrop {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  z-index: 1000;
  position: fixed;
  background: rgba(28, 110, 232, 0.85);
  width: 100%;
  height: 100%;
}
</style>
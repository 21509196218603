<template>
  <div class="px-6 lg:px-24 md:px-24 pt-8 h-screen overflow-y-auto" v-show="isActive">
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: ["title"],
  data(){
    return{
      isActive: true
    }
  }
};
</script>

<style scoped>
.maxHeight{
  max-height: 100%;
}
</style>

<template>
    <div>
        <formulateForm @submit="submitForm()" v-model="visaInfo">
           <FormulateInput
                                type="select"
                                label=""
                                name="visa_type"
                                :options="[ 'Work Visas' , 'Business Visas','Study Visas','Visitor Visas','Family Visas','Settlement Visas','Transit Visas','None']"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Visa Type"
                                validation="required"
                                
                                />
                                <div v-if="visaInfo.visa_type != 'None' ">

                               <FormulateInput
                                type="date"
                                label="Visa Expiration"
                                name="visa_expiry"
                                input-class="py-2 mt-5 mb-2  text-right outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Visa Expiration"
                                validation="required"
                                label-class="mt-4 text-gray-400 absolute"
                                
                                />
                                <FormulateInput
                                type="text"
                                label=""
                                name="visa_duration"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Visa Duration in Years"
                                validation="required"
                                
                                />
                                <div class="flex">
                                <button type="button"
                                @click="$emit('decrement', true)"
                                class="transition duration-500 ease-in-out text-center mt-5 bg-white py-4 block border-c1C6EE8 border-2 mr-3 text-primary w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                > Go Back </button>
                                <button type="submit"
                                class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-c1C6EE8 text-white w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                > Next </button>
                                </div>
                                </div>
                                <div class="flex" v-else>
                                    <button type="button"
                                @click="$emit('decrement', true)"
                                class="transition duration-500 ease-in-out text-center mt-5 bg-white py-4 block border-c1C6EE8 border-2 mr-3 text-primary w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                > Go Back </button>
                                <button type="button"
                                 @click ="$emit('complete', true)"
                                class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-c1C6EE8 text-white w-1/2 text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                > Continue
                                </button>
                                </div>
                                <div class="text-center mt-7">
                                    <b class="text-sm text-gray-400 hover:text-c1C6EE8  "> <a href="javascript:void(0)"  @click ="$emit('complete', true)"> Skip this for now </a> </b>
                                </div>
                        
        </formulateForm>
    </div>
</template>

<script>
    export default {
        props: ['token','email'],
        data(){
            return{
                visaInfo: {},
                processing:false
            }
        },
         methods: {
            submitForm(){
                this.processing = true
                this.visaInfo.email = this.email
                this.axios.post('migrant/update-visa', this.visaInfo, {
                     'Authorization': 'Bearer ' + this.token
                }).then(() => {
                    this.processing = false
                    this.$emit('complete', true)
                }).catch( () => {
                    this.processing = false
                })
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
                               
<template>
  <div class="payslips-wrapper grid grid-cols-1 gap-6">
    <div class="button-container">
    <router-link class="link inline-block my-1" :to="{name: 'migrant-document-upload', params: {slug: 'salary-payslip'}}"> Add Payslip</router-link>
    <btn-outline class="my-1" @click="downloadCSV()"> 
      Download CSV
      </btn-outline>
    <div class="w-40 sm:float-right mt-4" v-if="responseData"><pagination @fetch="updatePage"  :data="responseData" /></div>
    </div>

    <div class="" v-if="loadingPage">
      <profile-skeleton />
    </div>
    <div class="list-payslip-container" v-else>
      <t-card variant="noPadding">
        <template v-slot:header v-if="false">
          <div class="flex items-center justify-between check">
            <DropdownBulk title="Bulk Action" :options="BulkDD" />
            <t-button class="hidden md:block" variant="cardTop">Download CSV</t-button>
            <ion-icon class="block md:hidden text-2xl text-dashboardblue" name="download-outline"></ion-icon>
          </div>
        </template>
        
        <div v-if="(payslipsTable.tableData).length == 0" class=" mt-8 px-8 text-center bg-white rounded-md flex justify-content-center items-center" style="min-height: 60vh">
            <div class="w-full my-10">

            <p>
                You are yet to make add any payslip document.
                </p>
                <p>
                A list of all the payslips you have added would be shown here.
                    </p> 
                    <div class="my-10 block">
            <router-link :to="{name: 'migrant-document-upload', params: {slug: 'salary-payslip'}}" class="btn-primary px-4"> Add Payslip </router-link>
                    </div>
            </div>
        </div>
        <t-table v-if="(payslipsTable.tableData).length > 0" class="min-width" variant="payslips" :headers="payslipsTable.tableHead" :data="payslipsTable.tableData">
          <template v-slot:thead="props">
            <thead :class="props.theadClass">
              <tr :class="props.trClass" class="tr-select">
                <th class="text-left pl-4">
                  <!-- <t-checkbox name="selectAll" value="A" /> -->
                </th>
                <th class="text-left">
                  <DropdownTh :title="props.data[4].text" :options="payslipsTableHeadDD.dueDate" :list="true"/>
                </th>
                <th class="text-left">
                  <DropdownTh :title="props.data[1].text" :options="payslipsTableHeadDD.amount" :list="false"/>
                </th>
                <th class="text-left">
                  <DropdownTh :title="props.data[2].text" :options="payslipsTableHeadDD.companyName" :list="true"/>
                </th>
                <th class="text-left">
                  <DropdownTh :title="props.data[3].text" :options="payslipsTableHeadDD.jobTitle" :list="true"/>
                </th>
                <th class="text-left">
                  <DropdownTh :title="props.data[5].text" :options="payslipsTableHeadDD.remark" :list="true"/>
                </th>
                <th class="text-left text-sm">{{ props.data[6].text }}</th>
              </tr>
            </thead>
          </template>

          <template slot="row" slot-scope="props">
            <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? 'bg-gray-100' : '']" class="py-12 text-xs">
              <td :class="props.tdClass" class="pl-4">
                <t-checkbox name="selectOne" :value="props.row.id" />
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.fields.due_date | dateFormat }}
              </td>
              <td :class="props.tdClass" class="py-3">
                  {{ props.row.fields.salary }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.fields.company_name }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.fields.job_title }}
              </td>
              <td :class="props.tdClass" class="py-3">
                {{ props.row.remark ? props.row.remark : 'None' }}
              </td>
              <td :class="props.tdClass" class="py-3 flex items-center justify-evenly">
                <div class="eye w-5/12 cursor-pointer" @click="viewSlip(props.row)">
                  <ion-icon name="eye-outline"></ion-icon>
                </div>
                <div class="bin w-5/12 cursor-pointer" @click="deleteSlip(props.row.id, props.rowIndex)">
                  <ion-icon name="trash-outline"></ion-icon>
                </div>
              </td>
            </tr>
          </template>
        </t-table>
      </t-card>
    </div>
    <div class="empty-for-grid"></div>
    <div class="modal-wrapper" v-if="activateModal.id">
      <Modal :activateModal="activateModal" @close="closeModal()"/>
    </div>
  </div>
</template>

<script>
import Modal from '../../components/Modal.vue'
import DropdownTh from '../../components/Dropdown-for-table-heads.vue'
import DropdownBulk from '../../components/Dropdown-for-bulk.vue'
import Pagination from '../../components/Pagination.vue';
import ProfileSkeleton from '../../partials/skeleton/profile-skeleton.vue';
export default {
  components: { Modal, DropdownTh, DropdownBulk, Pagination, ProfileSkeleton },
  data() {
    return {
      BulkDD: [ "Message", "Coupon", "Premium article"],
      action: "",
      loadingPage: true,
        responseData: null,
      activateModal: {
        id: null,
        amount: null,
        jobTitle: null,
        compName: null,
        dueDate: null,
        location: null,
        remark: null,
        remarkBody: null
      },
      payslipsTableHeadDD: {
        companyName: [""],
        jobTitle: [""],
        dueDate: [""],
        remark: ["Underpaid", "Appropiate", "Overpaid"],
      },
      payslipsTable: {
        tableHead: [
          { value: "checkBox", text: "" },
          { value: "amount", text: "Amount(£)" },
          { value: "companyName", text: "Company Name" },
          { value: "jobTitle", text: "Job Title" },
          { value: "dueDate", text: "Due Date" },
          { value: "remark", text: "Remark" },
          { value: "actions", text: "Actions" },
        ],
        tableData: [
        
        ],
      },
    };
  },
  methods: {
    viewSlip(propsRowObj){
      this.activateModal.amount = propsRowObj.fields.salary
      this.activateModal.jobTitle = propsRowObj.fields.job_title
      this.activateModal.compName = propsRowObj.fields.company_name
      this.activateModal.dueDate = propsRowObj.fields.due_date
      this.activateModal.location = propsRowObj.fields.location
      this.activateModal.remark = propsRowObj.remark
      this.activateModal.remarkBody = propsRowObj.comment
      this.activateModal.id = propsRowObj.id
    },
    downloadCSV(){
      this.axios.get('migrant/export/payslips').then( response => {
        console.log(response);
      }).catch( err => {
        console.log(err);
      })
    },
    getPaySlips(){
        this.axios.get('migrant/payslips').then(response => {
          this.loadingPage =false
          this.responseData = response.data.data
          this.payslipsTable.tableData = response.data.data.data
        })
    },
    deleteSlip(id, index){
      // console.log(index);
      if(confirm('Are you sure you want to delete this payslip?')){
        this.axios.delete('migrant/document/'+id).then(response => {
            (this.payslipsTable.tableData).splice(index, 1);
          this.$toast.success(response.data.message, {timeout: 2000})
        }).catch(err => {
          this.$toast.error(err.response.data.message, {timeout: 2000})
        })
      }
    },
    updatePage(e){
      this.responseData = e.data
      this.payslipsTable.tableData = e.data.data
    },
    closeModal(){
      this.activateModal.id = null
    },
    
  },
  mounted(){
    this.$emit('pageTitle', 'PaySlips')
    this.getPaySlips()
  }
};
</script>

<style scoped>
.tr-select th select{
  appearance: none;
}
.link{
  @apply  px-4 py-2 text-white bg-blue-500 border border-transparent shadow-sm hover:bg-blue-600 rounded mr-2

}
</style>

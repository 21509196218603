<template>
    <div class="container h-screen min-w-full m-0 p-0">
        <div class="flex flex-col md:flex-row  content-center">
            <div class="md:w-2/4 bg-primary sm:w-full w-full" style="background: url('/images/login-bg.png')">
            <div class="logo ml-10 mt-10 lg:fixed md:fixed" >
           <router-link :to="{name: 'home'}">
                    <img src="../assets/images/logo.svg" alt="" width="231.7" height="37" />
                </router-link>
            </div>
                <div class=" flex flex-col  md:h-screen lg:h-screen sm:py-12 py-12 justify-center">
          
                   
                    <h3 class="text-white mx-10 font-normal font-whytte lg:text-6xl md:text-4xl sm:text-3xl text-2xl">
                        
                        <span v-if="!next">Reset Your Password</span>
                        <span v-else>Reset your password Authentication</span>
                    </h3>
               </div>
          </div>

            <div class="md:w-2/4 sm:w-full" >
               
                    
                    <password-token-request  v-if="!next" @verified="verifiedHandler" />

                    <password-request-form  :email="email"  :token="token" v-else />

                   
              
            </div>
    </div>
    
    
  </div>

</template>

<script>
import PasswordTokenRequest from '../partials/password-token-request.vue'
import PasswordRequestForm from '../partials/password-request-form.vue'

    export default {
        components: {
                PasswordRequestForm,
                 PasswordTokenRequest  
        },
        data(){
            return {
                email: '',
                token: '',
                next: false,
                registered: false,
                step: 1
            }
            
        },
        methods:{
            verifiedHandler(e){
                this.email = e.email
                this.next= true
                this.token = e.token
              }
           
        }
    }
</script>

<style scoped>
.formulate-input-error{
    color: red
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  textalign: "center";
}
.error {
  border: 1px solid red !important;
}
</style>
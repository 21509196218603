<template>
    <div class="container h-screen min-w-full m-0 p-0">
        <div class="flex flex-col md:flex-row flex-wrap content-center">
            <div class="md:w-2/4 bg-primary sm:w-full w-full" style="background: url('/images/login-bg.png')">
            <div class="logo ml-10 mt-10 lg:fixed md:fixed" >
                <router-link :to="{name: 'home'}">
                    <img src="../../assets/images/logo.svg" alt="" width="231.7" height="37" />
                </router-link>
            
            </div>
                <div class=" flex flex-col  md:h-screen lg:h-screen sm:py-12 py-12 justify-center">
          
                    <h3 class="text-white mx-10 font-normal font-whytte lg:text-6xl md:text-4xl sm:text-3xl text-2xl">
                        Login to check your <br> tier 2 compliance status
                    </h3>
               </div>
          </div>

            <div class="md:w-2/4 sm:w-full" >
                <div class=" flex flex-col md:max-w-md mx-auto  md:h-screen lg:h-screen sm:py-12 py-12 justify-center"> 
                     <FormulateForm
                        class="mx-10"
                        @submit="submitLoginForm"
                    >
                       <p class="text-text text-2xl">
                        Enter the email you used to create your account.
                        </p>
                        <FormulateInput v-if="!next"
                        v-model="email"
                        name="email"
                        type="email"
                        label=""
                        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                        placeholder="Email"
                        validation="required|email"
                        />
                        <p v-if="failureMsg" class=" text-red-600 text-md mt-3 ">{{failureMsg}}</p>
                        <div v-if="next">

                        <FormulateInput 
                        v-model="password"
                        name="password"
                        type="password"
                        placeholder="Your Password"
                        value=""
                        validation="required"
                        input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray"
                        />
                        <router-link :to="{name: 'forgot-password'}" class="float-right -mt-1 mb-2 text-text   "> Forgot Password </router-link>
                        </div>
                        <button
                        type="submit"
                        class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-primary text-white w-full text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                        > <spinner v-show="loading"/>
                        {{ loading ? '' : buttonText}}
                        </button>
                        <p class="text-text mt-3  ">
                            Don't have an account?  <router-link :to="{name: 'migrant-register'}" class="text-primary"> Sign Up </router-link>
                        </p>
                        
                    </FormulateForm>
                   
               </div>
            </div>
    </div>
    
    
  </div>

</template>

<script>
    export default {
        data(){
            return{
                email: null,
                password: null,
                next: false,
                userForm: {},
                buttonText: 'Continue',
                failureMsg: false,
                loading: false,
            }
        },
        methods:{
            submitLoginForm(){
                this.loading = true;
                this.failureMsg = false;
                this.userForm.email = this.email 
                if (!this.next) {
                    //check email exists
                    this.axios.post('migrant/login', {email: this.email}).then(() => {
                        this.loading = false;
                        this.next = true;
                    }).catch(() => {
                        this.loading = false;
                        this.failureMsg = "Sorry, This email does not exist on our system"
                    })

                }else{
                    this.axios.post('migrant/login', {email: this.userForm.email, password: this.password}).then((response) => {
                        this.loading = false;
                        //set token
                        localStorage.setItem("apiToken", response.data.token ); 
                        window.location = '/migrant/dashboard'
                    }).catch(() => {
                        this.loading = false;
                        this.failureMsg = "Your Password is incorrect, try again"
                    })
                    //do login

                }
               
            }
        },
        mounted(){
            
        }
    }
</script>

<style scoped>
.formulate-input-error{
    color: red
}
</style>
<template>
    <div id="overlay">
        <div class="spinner"></div>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style>
#overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    background: rgba(250, 250, 250, 0.562) center center no-repeat;
    z-index: 1000;
}
.spinner {
   position: absolute;
   left: 50%;
   top: 50%;
   height:70px;
   width:70px;
   margin:0px auto;
   -webkit-animation: rotation .6s infinite linear;
   -moz-animation: rotation .6s infinite linear;
   -o-animation: rotation .6s infinite linear;
   animation: rotation .6s infinite linear;
   border-left:6px solid rgba(0,174,239,.15);
   border-right:6px solid rgba(0,174,239,.15);
   border-bottom:6px solid rgba(0,174,239,.15);
   border-top:6px solid rgba(28, 110, 232, 0.822);
   border-radius:100%;
}


@-webkit-keyframes rotation {
   from {-webkit-transform: rotate(0deg);}
   to {-webkit-transform: rotate(359deg);}
}
@-moz-keyframes rotation {
   from {-moz-transform: rotate(0deg);}
   to {-moz-transform: rotate(359deg);}
}
@-o-keyframes rotation {
   from {-o-transform: rotate(0deg);}
   to {-o-transform: rotate(359deg);}
}
@keyframes rotation {
   from {transform: rotate(0deg);}
   to {transform: rotate(359deg);}
}
</style>
<template>
  <div class="flex relative min-h-screen bg-body">
    <!-- sidebar -->


    <!-- Desktop -->
    <aside class="hidden xl:block bg-c003A92 fixed left-0 top-0 bottom-0" style="min-width: 288px!important; width: 288px">
    <migrant-menu :pageTitle="title" />
    </aside>
    <!-- End Desktop -->
    <!--Tablet -->
    <aside class="hidden sm:block xl:hidden fixed left-0 top-0 bottom-0 bg-c003A92 w-20">
    <migrant-menu :pageTitle="title"  />
    </aside>
    <!-- End Tablet-->
  
  <!--MOBILE DRAWER -->

    <transition name="fade"  mode="out-in">
    <aside v-if="menuOpen" class="sm:hidden fixed left-0 top-0 bottom-0 bg-c003A92 w-20" style="z-index: 150">
      <migrant-menu :pageTitle="title"  />
    </aside>
    </transition>

<!-- END MOBILE DRAWER -->

    <!-- /sidebar -->

    <!-- content -->

    <div class="content">

      <!--top bar -->
      <div class="flex bg-body px-4 xl:px-8 md:px-4 py-4 fixed top-0 right-0 items-center justify-between left-0 sm:left-20 xl:left-72 " style="z-index: 100">
        <a href="javascript:void()" @click="toggleMenu" class="p-1 mt-2 mr-4 sm:hidden">
          <box-icon name='menu'></box-icon>
        </a>
        
        <div class="title-and-search w-8/12 flex" style="max-width: 1920px">
          <h1 class="text-2xl font-extrabold self-center" style="color: #242424">
            {{title}}
          </h1>
          <div v-if="false" class="hidden search h-10 w-64 ml-6 rounded-full bg-white p-1 pr-6 md:flex">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 mr-2 self-center ml-2" style="color: #707070" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z" />
            </svg>
            <input type="search" value="" class="outline-none w-full h-full bg-white" />
          </div>
        </div>

        <div class="account-info w-4/12 flex justify-end items-center">

          <div class="bell-notif-dd mr-2 md:mr-10">
            <t-dropdown variant="notif">
              <div slot="trigger" slot-scope="{ mousedownHandler, focusHandler, blurHandler, keydownHandler }">
                <button class="relative flex items-center" @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler">
                  <ion-icon class="text-dashboardgray text-2xl" name="notifications"></ion-icon>
                  <span class="red-dot absolute top-0 right-1 bg-dashboardred w-2 h-2 rounded-full" v-if="notifications.length > 0"></span>
                </button>
              </div>

              <div class="grid grid-cols-1" style="z-index: 100" slot-scope="{ blurHandler }">
                <div v-if="notifications.length > 0">
                <div v-for="(notif, index) in notifications" :key="index" class="flex justify-center items-center w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out focus:outline-none hover:bg-gray-50 focus:bg-gray-50" role="menuitem" @blur="blurHandler">
                  <div class="image w-3/12">
                  <box-icon type='solid' name='bell-ring' class="h-10 w-10 rounded-full " color="#ccc"></box-icon>
                  </div>
                  <div class="body w-8/12 flex flex-col cursor-pointer">
                    <h5 class="text-md font-bold text-left">{{notif.data.title}}</h5>
                    <p class="text-xs text-left">{{notif.data.excerpt}}</p>
                  </div>
                  <div class="more w-1/12 flex flex-col justify-end cursor-pointer">
                    <ion-icon name="ellipsis-vertical"></ion-icon>
                  </div>
                </div>
                <div class="show-all py-3 bg-cF5F5FC">
                  <button @click="markAsRead()" class=" w-full text-center text-c1C6EE8 text-sm font-bold">Mark All As Read</button>
                </div>
                </div>
                <div v-else class="w-full text-center my-5 mx-2 text-gray-400 ">
                  No New Notifications
                </div>
              </div>
            </t-dropdown>
          </div>

          <div class="profile-pic hidden md:block mx-2">
            <img class=" w-8 h-8 rounded-full" src="../assets/images/articles/facebook/facebook.png" />
          </div>

          <div class="profile-dd">
            <t-dropdown variant="profile">
              <div slot="trigger" class="flex items-center" slot-scope="{ mousedownHandler, focusHandler, blurHandler, keydownHandler }">
                
                <button class="flex items-center justify-center username" @mousedown="mousedownHandler" @focus="focusHandler" @blur="blurHandler" @keydown="keydownHandler" :class="'bar-loading'">
                 <span class="hidden sm:inline" > {{migrant.name}} </span>  
                 <box-icon name='chevron-down-circle' color="gray" class="ml-2" ></box-icon>
                </button>
              </div>

              <div slot-scope="{ blurHandler }" class="text-center">
                <router-link :to="{name: 'migrant-profile'}" class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100" role="menuitem" @blur="blurHandler">
                  Your Profile
                </router-link>
                <button @click="logout()" class="block w-full px-4 py-2 text-sm leading-5 text-gray-700 transition duration-150 ease-in-out hover:bg-gray-100 focus:outline-none focus:bg-gray-100" role="menuitem" @blur="blurHandler">
                  Logout
                </button>
              </div>
            </t-dropdown>
          </div>
        </div>
      </div>

      <!--end top bar -->
      <div class="block">
      <div class="mt-16 ">

        <transition name="fade"  mode="out-in">
          <router-view @pageTitle="title = $event" :migrant="migrant" @migrantDataUpdate="migrant = $event" @loading="loading = $event " />
        </transition>

      </div>
     
      </div>
        <div v-if="loading" class="loading absolute left-0 top-0 bottom-0  right-0 " style="background: rgba(255,255,255,0.9) ">
              <div class="loader">
              </div>
        </div>
    </div>
  </div>
</template>

<script>
import MigrantMenu from '../partials/MigrantMenu.vue';
// import articles from '../partials/articles.vue';
export default {
  components: { MigrantMenu },
  // components: { articles },
  name: "MigrantLayout",

  data() {
    return {
      migrant: {},
      loading: false,
      menuOpen: false,
      title: 'Dashboard',
      notifications: [
        ]
    };
  },
  methods:{
    toggleMenu(){
        this.menuOpen = !this.menuOpen
    },
    markAsRead(){
      this.axios.get('user/notifications/mark-as-read').then(() => {
          this.notifications = []
      })
    },
    logout(){
       localStorage.removeItem("apiToken");
       window.location = '/'
    },
    initializePageData(){
       this.axios
      .get("/user")
      .then((response) => {
        this.axios.get('notifications').then(response => {
          this.notifications = response.data.data
        })
        this.migrant = response.data.data;
      })
      .catch(() => {
        window.location = "/pre-login";
      });
    }
  },
  watch: {
      $route: function(){
        this.menuOpen = false
      } 
  },
  created() {
    this.initializePageData()
  },
};
</script>
<style>
.slide-enter-active,
.slide-leave-active {
  transition: opacity 1s, transform 1s;
}
.slide-enter,
.slide-leave-to {
  opacity: 0;
  translate: (-30%);
}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
}
.loader {
  border: 6px solid #f3f3f3;
  border-radius: 50%;
  border-top: 6px solid #1C6EE8;
  width: 50px;
  height: 50px;
  -webkit-animation: spin 500ms linear infinite; /* Safari */
  animation: spin 500ms linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.username{
 @apply text-sm;
}
</style>

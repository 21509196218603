<template>
  <div class="backdrop">
    <div class="modal relative grid grid-cols-1 gap-1 ">
      <!-- start -->
      <div class="text-center center mx-auto mb-2">
          <img src="@/assets/images/stripe.png"  alt="" />
      </div>
      <div class="">
       
          <h4 class="text-2xl font-extrabold">Pay for ILR request </h4>
  <div class="my-2 mt-3">
    <label>Card Number</label>
    <div class="border-b broder-gray-500 py-2 my-1">
    <div id="card-number"></div>
    </div>
    <label>Card Expiry</label>
    <div class="border-b broder-gray-500 py-2 my-1">
    <div id="card-expiry"></div>
    </div>
    <label>Card CVC</label>
    <div class="border-b broder-gray-500 py-2 my-1">
    <div id="card-cvc"></div>
    </div>
    <div id="card-error"></div>
    
    <div class="w-full">

    <button
      class="transition mt-12 w-full rounded duration-500 ease-in-out text-center px-4 py-4 block   text-white  text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
      :class="(!processing ) ? 'border-c1C6EE8 bg-c1C6EE8' : 'bg-gray-200  border-gray-200'"
     @click="createToken">Pay £{{cost }} </button>

    </div>
    <div class="closeButton absolute -top-2 -right-10 text-2xl text-white cursor-pointer" @click="$emit('close')">
       x
      </div>
  </div>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  props: ['cost'],
  data () {
    return {
      token: null,
      cardNumber: null,
      cardExpiry: null,
      cardCvc: null,
      processing: false,
    };
  },
  computed: {
    stripeElements () {
      return this.$stripe.elements();
    },
  },
  mounted () {
    // Style Object documentation here: https://stripe.com/docs/js/appendix/style
    const style = {
      base: {
        color: 'black',
        fontFamily: '"Whyte", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        border: '1px solid #ccc',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    };
        this.cardNumber = this.stripeElements.create('cardNumber', { style });
        this.cardNumber.mount('#card-number');
        this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
        this.cardExpiry.mount('#card-expiry');
        this.cardCvc = this.stripeElements.create('cardCvc', { style });
        this.cardCvc.mount('#card-cvc');
  },
  beforeDestroy () {
    this.cardNumber.destroy();
    this.cardExpiry.destroy();
    this.cardCvc.destroy();
  },
  methods: {
    async createToken () {
      this.processing = true;
      const { token, error } = await this.$stripe.createToken(this.cardNumber);
      if (error) {
        // handle error here
        this.processing = false;
        document.getElementById('card-error').innerHTML = error.message;
        return;
      }
      this.$emit('completed', token)
      // console.log(token);
      // handle the token
      // send it to your server
    },
  }
};
</script>

<style scoped>
#custom-button {
  height: 30px;
  outline: 1px solid grey;
  background-color: green;
  padding: 5px;
  color: white;
}

#card-error {
  color: red;
}
</style>
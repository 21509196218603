<template>
    <div>
         <div class=" flex flex-col md:max-w-md mx-auto  md:h-screen lg:h-screen sm:py-12 py-12 justify-center">
         <FormulateForm
                        class="mx-10"
                       
                    >
                       <p class="text-text text-2xl">
                        {{ (next == true) ? 'Enter the five digit code sent to ' + email : 'Enter email to create account' }}
                        </p>

                        <p class="text-red-400" v-if="failureMsg"> {{ failureMsg}}</p>
                        <div  v-if="!next">
                                <FormulateInput
                                v-model="email"
                                type="email"
                                label=""
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Email"
                                validation="email"
                                
                                />
                                <p class="text-red-400" v-show="loginUser">
                                            This account is already registered, <router-link class="text-c1C6EE8" :to="{name: 'migrant-login'}">
                                                click here to login 
                                                </router-link> 
                                    
                                </p>
                                <!-- <vue-recaptcha-v2  @verify="onVerify" :loadRecaptchaScript="true" :sitekey="siteKey"></vue-recaptcha-v2> -->
                                <button
                                type="submit"
                                @click="submitRegForm()"
                                class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-primary text-white w-full text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 "
                                >
                                <spinner v-show="processing" />
                                    {{ processing ? '' : buttonText}}

                                </button>
                        </div>
                        <div v-else class="">
                            <v-otp-input
                            inputClasses="py-3 text-center mt-5 mb-2 w-5/6 mr-4 outline-none text-text bg-white border-b-1 bg-white border-solid border-2 border-t-0 border-l-0 border-r-0 border-b-1 font-extrabold border-gray otp-input text-2xl "
                            :numInputs="5"
                            separator="  "
                            :shouldAutoFocus="true"
                            @on-complete="handleOnComplete"
                            @on-change="handleOnChange"
                            />
                            <div class="mr-4">
                                <button type="button" @click="VerifyEmail()"  class="transition duration-500 ease-in-out text-center center mt-5 bg-c1C6EE8 py-4 border-primary text-white block text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 w-full">
                                 
                                 <spinner v-show="processing"/>
                                    {{ processing ? '' : 'Authenticate'}}
                          
                                </button>
                            </div>
                        </div>
                       
                        <p class="text-text mt-3  text-base">
                            Already have an account?  <router-link :to="{name: 'migrant-login'}" class="text-c1C6EE8"> Login </router-link>
                        </p>
                        
                    </FormulateForm>
         </div>
    </div>
</template>

<script>
    import OtpInput from "@bachdgvn/vue-otp-input"
    export default {
        name: "PreRegistrationForm",
        components : {
           'v-otp-input': OtpInput,
         
        },
        data(){
            return{
                email: null,
                password: null,
                failureMsg: false,
                next: false,
                buttonText: 'Continue',
                processing: false,
                registered: false,
                token: '',
                loginUser: false,
                robot: false,
                siteKey: null
            }
        },
        mounted(){
            this.siteKey = (process.env.VUE_APP_RECAPTCHA);
        },
        methods : {
             submitRegForm(){
                //   if(!this.robot){
                //     this.$toast.error('Recaptcha Invalid', {duration: 5000})
                //     return false
                // }
                 this.failureMsg = false;
                 this.processing = true;
                 this.axios.post('migrant/register', {email: this.email}).then(response => {
                     this.processing = false;
                     if(response.data.action == 'verify-email'){
                         this.next = true;
                         this.$emit('next', true)
                     }
                     if(response.data.action == 'login'){
                        //  if(response.data.setup_stage == 1){
                             this.loginUser = true;
                        //  }
                        //  this.$emit('registered', {email: this.email, step: response.data.setup_stage, token: response.data.token})
                     }
                     
                 }).catch(error => {
                     this.processing = false;
                     this.$emit('loading', false)
                     this.$toast.error(error.response.message, {delay: 6000})
                    //  console.log(error);
                 })
            },
            VerifyEmail(){
                this.failureMsg
                this.processing = true;

               


                this.axios.post('migrant/verify', {email: this.email, token: this.token}).then( response => {
                    this.processing = false;
                    this.token = response.data.token;
                    localStorage.setItem("apiToken", response.data.token );
                    this.$emit('registered', {email: this.email, step: 1, token: this.token})
                }).catch(()=>{
                    this.processing = false;
                    this.failureMsg = "Verification code is invalid or expired"
                })
            },
            handleOnComplete(value){
                // this.processing = true
                this.token = value;
                this.VerifyEmail();
            },
            handleOnChange(){
                
            },
            onVerify:function(response){
                if (response) {
                    this.form.robot = true
                }
            }

        }
    }
</script>

<style lang="scss" scoped>

</style>
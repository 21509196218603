<template>
  <div class="bg-white rounded-lg ">
    <ul class="tab-header flex justify-center list-none border-gray-300 border-b text-dashboardgray">
      <li v-for="(tab, index) in tabs" :key="tab.title" @click="selectedTab(index)" 
        :class="{'border-b-2 border-dashboardblue text-dashboardblue': (index === selectedIndex)}"
        class="mr-5 md:mr-12 pt-6 pb-1 md:pt-6 md:pb-6 text-md font-bold cursor-pointer"
      >
        {{tab.title}}
      </li>
    </ul>
    <slot></slot>
  </div>
</template>

<script>
export default {
  data(){
    return{
      selectedIndex: 0,
      tabs: []
    }
  },
  created(){
    this.tabs = this.$children
  },
  mounted(){
    this.selectedTab(0)
  },
  methods: {
    selectedTab(i){
      this.selectedIndex = i
      this.tabs.forEach((tab, index) => {
        tab.isActive = (index === i)
      })
    }
  }
};
</script>

<style></style>

<template>
    <div class="container h-screen min-w-full m-0 p-0">
        <div class="flex flex-col md:flex-row  content-center">
            <div class="md:w-2/4 bg-primary sm:w-full w-full">
            <div class="logo ml-10 mt-10 lg:fixed md:fixed bg-primary" >
           <router-link :to="{name: 'home'}">
                    <img src="../../assets/images/logo.svg" alt="" width="231.7" height="37" />
                </router-link>
            </div>
                <div class=" flex flex-col  md:h-screen lg:h-screen sm:py-12 py-12 justify-center" style="background: url('/images/signup.png')">
          
                    <h3 class="text-white mx-10 font-normal font-whytte lg:text-6xl md:text-4xl sm:text-3xl text-2xl" v-if="registered">
                        Set up your tier 2 <br> compliance account </h3>
                    <h3 v-else class="text-white mx-10 font-normal font-whytte lg:text-6xl md:text-4xl sm:text-3xl text-2xl">
                        
                        <span v-if="!next">Create your skilled worker <br> compliance account</span>
                        <span v-else>Authenticate your email address</span>
                    </h3>
               </div>
          </div>

            <div class="md:w-2/4 sm:w-full" >
               
                    
                    <pre-registration-form  v-if="!registered" @loading="$emit('loading',$event)" @next="next = $event" @registered="registeredHandler($event)"/>
                    <post-registration-form  :email="email" @loading="$emit('loading',$event)" :last_step="step" :token="token" v-else />

                   
              
            </div>
    </div>
    
    
  </div>

</template>

<script>
import PreRegistrationForm from '../../partials/pre-registration-form.vue'
import PostRegistrationForm from '../../partials/post-registration-form.vue'

    export default {
        components: {
                PostRegistrationForm,
                 PreRegistrationForm  
        },
        data(){
            return {
                next: false,
                registered: false,
                step: 1
            }
            
        },
        methods:{
            registeredHandler(e){
                this.email = e.email
                this.step = e.step
                this.token = e.token
                this.registered = true
            }
           
        }
    }
</script>

<style scoped>
.formulate-input-error{
    color: red
}
.otp-input {
  width: 40px;
  height: 40px;
  padding: 5px;
  margin: 0 10px;
  font-size: 20px;
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  textalign: "center";
}
.error {
  border: 1px solid red !important;
}
</style>
<template>
  <div class="backdrop">
    <div class="modal relative grid grid-cols-1 gap-1 ">
      <!-- start -->
      <div class="">
       
          <h4 class="text-2xl">Change Password</h4>
      <FormulateForm
        v-model="passwordForm"
        @submit="ChangePassword"
      >
      <div :class="(messageStatus == 'true') ? 'text-green-400 mt-2 font-bold' : 'text-red-500 mt-2 font-bold' ">
          {{message}}
      </div>
        <div class="flex justify-between  border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray ">
                                <FormulateInput
                                :type="passwordField1"
                                label=""
                                name="old_password"
                                input-class="py-2 mt-5 mb-2  outline-none text-text bg-white"
                                placeholder="Old Password"
                                validation="required|min:8,length"
                                />

                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField1 = 'text'" v-show="passwordField1 == 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg></i>
                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField1 = 'password'" v-show="passwordField1 != 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>
                                </i>
            </div>
        <div class="flex justify-between  border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray ">
                                <FormulateInput
                                :type="passwordField"
                                label=""
                                name="password"
                                input-class="py-2 mt-5 mb-2  outline-none text-text bg-white w-full"
                                placeholder="New Password"
                                validation="required|min:8,length"
                                validation-name="New Password"
                                />

                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField = 'text'" v-show="passwordField == 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg></i>
                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField = 'password'" v-show="passwordField != 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>
                                </i>
            </div>
        <div class="flex justify-between  border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray ">
                                <FormulateInput
                                :type="passwordField"
                                label=""
                                name="password_confirmation"
                                input-class="py-2 mt-5 mb-2  outline-none text-text bg-white w-full"
                                placeholder="Confirm New Password"
                                validation="required|min:8,length|confirm:password"
                                validation-name="Password Verification"
                                />

                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField = 'text'" v-show="passwordField == 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg></i>
                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField = 'password'" v-show="passwordField != 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>
                                </i>
            </div>
        <button type="submit" :disabled="processing" 
                                class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-c1C6EE8 text-white w-full text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 ">
                                
                                 <spinner v-if="processing" />
                                    {{ processing ? '' : 'Save New Password'}}
                          
                                </button>
      </FormulateForm>
      
      <div class="closeButton absolute -top-2 -right-10 text-2xl text-white cursor-pointer" @click="closeModal()">
       x
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            passwordField1: 'password',
            passwordField: 'password',
            processing: false,
            passwordForm: {},
            message: null,
            messageStatus: false,

        }
    },
  methods: {
    
    ChangePassword(){
      this.message  = this.messageStatus = null
      this.processing  = true;
        this.axios.post('user/change-password', this.passwordForm).then((response) => {
          //logout
          this.processing  = false;
            this.message = response.data.message
            this.messageStatus = response.data.status
            if(response.data.status == 'true'){
                // Todo: logout();
                localStorage.removeItem("apiToken"); 
                window.location = '/pre-login'
            }

        }).catch(() => {
            this.processing = false 
            this.messageStatus = 'false'
            this.message = 'An error occurred on the server'
        })
    },
    closeModal() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>

</style>
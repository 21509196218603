<template>
    <div>
         <div class=" flex flex-col md:max-w-md lg:mx-auto md:mx-auto mx-2.5 px-7 sm:py-12 py-12 justify-center " v-if="step <= 3">
        
                       <p class="text-text text-xl">
                        Register a new Skilled Worker <br> Compliance account                        </p>

                        <div class="flex mt-11 mb-2 justify-between">
                            <p class="text-sm text-text tracking-widest "> {{info[step]}} </p>
                            <p class="text-sm text-text font-extrabold"> {{step}} of 3</p>
                        </div>
                      
                       <progress-bar :percentage="step/3 * 100" color="c1C6EE8" class="mb-5"/>
                            <div v-show="step==1">
                                 <step-one :email="email" :token="token" @complete="step = 2"/>
                              
                            </div>
                            <div v-show="step == 2">

                                <step-two @complete ="step=3" :token="token" :email="email" @decrement="step--"/>

                            </div>
                            <div v-show="step==3">
                              <step-three @decrement="step--" @complete="registrationCompleted" :token="token" :email="email"/>
                            </div>
                        
                        
                    
         </div>
    </div>
</template>

<script>
import ProgressBar from '../components/ProgressBar'
import StepOne from './migrant/forms/step-one.vue'
import StepTwo from './migrant/forms/step-two.vue'
import StepThree from './migrant/forms/step-three.vue'

    export default {
        props : {
            email: {type: String, required: true},
            token: {type: String, required: true},
            last_step: {type: Number}
        },
  components: { 'progress-bar' : ProgressBar, 'step-one' : StepOne, 'step-two': StepTwo, 'step-three' : StepThree },
  data(){
            return{
                migrantData: {},
                passwordField: 'password',
                step: this.last_step,
                info: ['Personal Details','Personal Details', 'Visa Details', 'Employment Details']
            }    
        },
    methods:{
        registrationCompleted(){
            
        },
        
    }
        
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <formulateForm
        @submit="submitForm"
        v-model="personalInfo"
        >
                         <FormulateInput
                                type="text"
                                name="name"
                                label=""
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Name (as written on passport)"
                                validation="required"
                                
                                />
                                <FormulateInput
                                type="email"
                                name="email"
                                label=""
                                :value="email"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Email"
                                validation="email"
                                :readonly="true"
                                />
                                <!-- <FormulateInput
                                type="tel"
                                label=""
                                name="phone"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Phone Number"
                                validation="required"
                                /> -->

                                
                                <FormulateInput
                                type="text"
                                label=""
                                name="country"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Nationality"
                                validation="required"
                                
                                />
                                <vue-phone-number-input v-model="personalInfo.phone" :required="true" class="mt-5 "  @update="CheckPhone"/>
                                <p class="text-red-500 font-bold mt-1" v-if="phoneError && personalInfo.phone"> Please double check to ensure this phone number is correct </p>
                                <FormulateInput
                                type="text"
                                label=""
                                name="address"
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Address"
                                validation="required"
                                
                                />
                                <FormulateInput
                                type="date"
                                label="Date of Birth"
                                name="date_of_birth"
                                input-class="py-2 mt-5 mb-2  text-right outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Date of Birth"
                                validation="required"
                                label-class="mt-4 text-gray-400 absolute"
                                
                                />
                                <div class="flex justify-between  border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray ">
                                <FormulateInput
                                :type="passwordField"
                                label=""
                                name="password"
                                input-class="py-2 mt-5 mb-2  outline-none text-text bg-white w-80"
                                placeholder="Password"
                                validation="required|min:8,length"
                                />

                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField = 'text'" v-show="passwordField == 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg></i>
                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white" @click="passwordField = 'password'" v-show="passwordField != 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>
                                </i>
                                </div>
                                <button type="submit" :disabled="processing"
                                class="transition duration-500 ease-in-out text-center mt-5 bg-c1C6EE8 py-4 block border-c1C6EE8 text-white w-full text-lg hover:opacity-80 focus:opacity-80 transform  hover:scale-102 ">
                                
                                 <spinner v-if="processing" />
                                    {{ processing ? '' : 'Next'}}
                          
                                </button>
                            
        </formulateForm>
    </div>
</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
    export default {
        components: {
            'vue-phone-number-input' :  VuePhoneNumberInput
        },
        props: ['email','token'],

        data(){
            return{
                 passwordField: 'password',
                 personalInfo: {},
                 failureMsg: false,
                 processing: false,
                 phoneError: true
            }
        },
        methods: {
            submitForm(){
                this.processing = true
                    this.failureMsg = false;
                    this.personalInfo.email = this.email;
                    this.axios.post('migrant/update-personal-info', this.personalInfo, {
                        'Authorization': 'Bearer ' + this.token})
                                     .then(() => {
                        this.processing = false,
                        this.$emit('complete', true)
                    }).catch(()=>{
                        this.processing = false,
                        this.failureMsg = "An error occurred on the server"
                    })
            },
            CheckPhone(e){
                this.phoneError = false;
                if(e.isValid ==false){
                    this.phoneError = true;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
         <div class=" flex flex-col md:max-w-md mx-auto  md:h-screen lg:h-screen sm:py-12 py-12 justify-center">
         <FormulateForm
                        class="mx-10"
                         @submit="submitPasswordChange()"
                       
                    >
                    <p class="text-text text-2xl">
                       Create a new Password
                         </p>

                     
                        <p class="text-red-400 mt-3" v-if="failureMsg"> {{ failureMsg}}</p>
                        <div>

                             <div class="flex justify-between  border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray ">
                                <FormulateInput
                                :type="passwordField"
                                label=""
                                name="password"
                                v-model="password"
                                input-class="py-2 mt-5 mb-2  outline-none text-text bg-white w-80"
                                placeholder="Password"
                                required="true"
                                validation="required|min:8,length"
                                />

                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white cursor-pointer" @click="passwordField = 'text'" v-show="passwordField == 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                                </svg></i>
                                <i class="py-2 mt-5 mb-2  outline-none text-gray-400 bg-white cursor-pointer" @click="passwordField = 'password'" v-show="passwordField != 'password'">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21" />
                                    </svg>
                                </i>
                                </div>

                                <!-- <FormulateInput
                                v-model="password"
                                type="password"
                                label=""
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Enter New Password"
                                validation="required|min:8,length"
                                
                                /> -->
                                <!-- <FormulateInput
                                v-model="password_confirmed"
                                type="password"
                                label=""
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Confirm New Password"
                                validation="required|min:8,length"
                                
                                /> -->
                                <FormulateInput
                                type="submit"
                               :disabled="processing"
                                label="Save"
                                :input-class="processing ? 'btn-reset-disabled' : 'btn-reset'"
                                />
                        </div>
                        
                       
                       
                        
                    </FormulateForm>
         </div>
    </div>
</template>

<script>
   
    export default {
        props: ['email', 'token'],
        components : {
         
        },
        data(){
            return{
                password: null,
                passwordField: 'password',
                password_confirmed: null,
                failureMsg: false,
                next: false,
                buttonText: 'Continue',
                processing: false,
                registered: false,
               
            }
        },
        methods : {
            submitPasswordChange(){
                 this.processing = true
                 this.failureMsg = false;
                 this.axios.post('password-reset', {email: this.email, token: this.token, password: this.password, password_confirmed: this.password_confirmed}).then( () => {
                     this.processing = false
                     this.$toast.success("Password Changed. Login to continue", {timeout: 5000})
                    window.location = '/pre-login'
                 }).catch(error => {
                     this.processing = false
                    this.$toast.error(error.response.data.message, {timeout: 5000})
                     console.log(error);
                 })
            },
           
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <navigation :links="links" />
                <router-link :to="{name: 'home'}">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 lg:mr-32 my-7 lg:my-14 md:my-14 md:mr-16 sm:mr-16 mr-7 float-right" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
                </router-link>
        <div class="h-5/6 w-full flex flex-col justify-center ">

                <router-link :to="{name: 'migrant-register'}">
                            <div class="pre-authenticate-button ">
                       Create Migrant Account<svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 float-right" style="margin-top: 4px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
                </svg>

                </div>
                </router-link>
                            <div class="pre-authenticate-button  mt-8">
                        Create Company Account <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 float-right" style="margin-top: 4px" fill="none" viewBox="0 0 24 24" stroke="currentColor">
  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
</svg>
                </div>
               
        </div>

        <home-footer />   





    </div>
</template>

<script>

import Home_Footer from '../partials/home_footer.vue'
import navigation from '../components/navigation.vue'
    export default {
  components: { 'navigation' : navigation, 'home-footer':Home_Footer },
    
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
        <div class="w-full flex flex-col justify-center items-center rounded-lg bg-white px-8" style="min-height: 80vh">
                <box-icon name='check-double' size="lg" color="#12A250"></box-icon>
            <h2 class="text-2xl font-extrabold"> You Successfully made a service request </h2>
            <p class="my-8 text-c707070">
               One of our legal professionals will make contact in the next 24-48 hours after they have reviewed your case.
            </p>
            <div class="my-2 text-center">
            <router-link class="btn-req-o py-5 block m-1 sm:inline-block " :to="{name: 'migrant-request'}"> View Request List </router-link>
            <router-link class="btn-req py-3 block m-1 sm:inline-block" :to="{name: 'migrant-request-create'}"> Make Another Request </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        data(){
            return {
                request: "Extension"
            }
        },
        methods: {
            verify(){
                this.axios.post('service-request-payment-verify', {token: this.$route.query.token})
            }
        },
        mounted() {
            this.verify()
        }
    }
</script>

<style  scoped>
.btn-req-o{
  @apply bg-white text-c1C6EE8 px-5 py-3 border-c1C6EE8 border mr-4 rounded
}
.btn-req{
  @apply bg-c1C6EE8 px-5 py-3 border-c1C6EE8 border mx-4 text-white rounded transition
}
.btn-req:hover{
  @apply bg-c1C6EE8 px-5 py-3 border-c1C6EE8 border mx-4 text-white rounded opacity-70
}
</style>
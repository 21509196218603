<template>
    <div class="">
         <img v-if="isImage(document.file[0])" :src="document.file[0].original_url" class="w-full h-full mx-auto"/>
        <div v-if="!isImage(document.file[0])" class="w-full h-full bg-gray-100 text-center  ">
        <box-icon type='solid' name='file-pdf' color="rgba(308, 113, 113)" class="h-full w-full"></box-icon>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['document'],
        methods:{
            isImage(media){
                if(media.mime_type == 'image/jpeg' || media.mime_type == 'image/jpg' || media.mime_type == 'image/png' || media.mime_type == 'image/gif'){
                    return true;
                }else{
                    return false
                }
            },
            isPDF(media){
                if(media.mime_type == 'document/pdf'){
                    return true;
                }else{
                    return false;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>

  <div id="appVue">
    <spinner-overlay v-show="loading" />
    
     <router-view class="view" @loading="loading = $event"></router-view>
  
  </div>
</template>

<script>
import spinnerOverlay from './components/spinner-overlay.vue'

export default {
  components: { spinnerOverlay },
  data(){ 
    return {
      loading: false,
    }
  },
  name: 'App',
  
}
</script>


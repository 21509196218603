<template>
    <div class="flex justify-between items-center" v-if="data.total > 1">
        <!-- Hello World -->
      
        <span>{{data.from}} - {{data.to}} of {{data.total}} </span>
        <box-icon type='solid' class="cursor-pointer"  @click="previous()" :color=" data.prev_page_url == null ? '#B8B8B8' : '#242424' "   name='chevron-left'></box-icon>
        <box-icon type='solid' class="cursor-pointer" @click="next()" :color=" data.next_page_url == null ? '#B8B8B8' : '#242424' " name='chevron-right'></box-icon>
    </div>
</template>

<script>
    export default {
        props: { data :{ type: Object, required: true}},
        methods: {
            previous(){
                if(this.data.prev_page_url != null){
                    this.axios.get(this.data.prev_page_url).then((response) => {
                        this.$emit('fetch', response.data)
                    })        
                }
            },
            next(){
                   if(this.data.next_page_url != null){
                    this.axios.get(this.data.next_page_url).then((response) => {
                        this.$emit('fetch', response.data)
                    })        
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div class="todo bg-white flex justify-evenly items-center py-2 px-5">
        <p class="w-9/12 text-sm">{{todo.title}}</p>
        <p class="w-2/12 text-sm text-dashboardblue font-black text-right"><a href="#" class="cursor-pointer">{{todo.action}}</a></p>
        <button class="w-1/12 text-right text-dashboardgray text-sm text-bold">x</button>
    </div>
</template>

<script>
export default {
    name: 'DashboardTodo',
    props: {
        todo: Object
    }
}
</script>

<style>

</style>
<template>
    <div class="grid grid-cols-1">
     
        <div class="profile_skeleton"></div>
     
        
        
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style lang="scss" scoped>

</style>

<template>
    <div class="border border-cE1E1E1 rounded-md px-8 py-8 bg-white">
        <div class="text-base text-c515151"> {{title}}  </div>
        <div :class="textClass + ' text-3xl font-extrabold mt-2' "> {{ value }} </div>
    </div>
</template>

<script>
    export default {
        props: ['title', 'value', 'textClass']
    }
</script>

<style lang="scss" scoped>

</style>
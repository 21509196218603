<template>
<div>

    <!-- <label :for="name" class=""> -->
                        <router-link :to="{name: 'migrant-document-upload', params: {slug: document.slug ? document.slug : document.document.slug }}">
        <div class="flex justify-between items-center my-5 btn-hoverable">

                <div class="flex items-center">
                    <div class="image_holder mr-4 h-10 w-10">
                        
                        <file-thumbnail-preview v-if="document.file" :document="document" />

                        <box-icon v-if="!document.file" name='image-alt' class="w-10 h-10" color="gray" ></box-icon>
                    </div>
                    <span class="title" :class="(image ? 'font-bold' : '' )">
                        {{document.name ? document.name : document.document.name }}
                    </span>
                </div>
                <div class="flex-end">

                    <div class="right" >
                        <box-icon v-if="!document.file" name='upload' color="gray" ></box-icon>
                        <box-icon v-else name='edit' color="gray" class="cursor-pointer"></box-icon>
                    </div>
                </div>
            <!-- <input type="file" @change="selectFile" name="" :id="name" class="hidden"> -->
    </div>
            
                        </router-link>
    <!-- </label> -->
</div>

</template>

<script>
import FileThumbnailPreview from './FileThumbnailPreview.vue';
    export default {
    name: 'FileInputComponent',
    props:['document', 'id'],
    components: { FileThumbnailPreview },
        data(){
            return{
                image: null,
            }
        },
        methods: {
            selectFile(e){
                
                  let file = e.target.files[0];
                    if (file) {
                        this.image = (URL.createObjectURL(file))
                    }
                this.$emit('fileSelected', {file: file, preview: this.image, id: this.id})
            },
            removeFile(){
                this.image = null;
            }

        }
    }
</script>

<style scoped>

.btn-hoverable:hover{
    @apply transition font-bold cursor-pointer 
}

</style>
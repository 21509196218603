export default(
[
    {path: 'dashboard', component: require('../views/migrant/dashboard.vue').default, name: 'migrant-home' }, 
    {path: 'documents', component: require('../views/migrant/document-lists.vue').default, name: 'migrant-documents' }, 
    {path: 'documents-upload/:slug', component: require('../views/migrant/documents.vue').default, name: 'migrant-document-upload' }, 
    {path: 'profile', component: require('../views/migrant/profile.vue').default, name: 'migrant-profile' }, 
    {path: 'requests', component: require('../views/migrant/service-requests.vue').default, name: 'migrant-request' }, 
    {path: 'requests/create', component: require('../views/migrant/create-service-request.vue').default, name: 'migrant-request-create' }, 
    {path: 'requests/edit/:id', component: require('../views/migrant/edit-service-request.vue').default, name: 'migrant-request-edit' }, 
    // {path: 'demo', component: require('../views/migrant/demo.vue').default, name: 'demo' },
    {path: 'absences-calculator', component: require('../views/migrant/absences-calculator.vue').default, name: 'absences-calculator' },
    {path: 'payslips', component: require('../views/migrant/payslips.vue').default, name: 'pay-slips' },
    {path: 'help', component: require('../views/migrant/help.vue').default, name: 'help' },
    {path: 'payment/success', component: require('../views/migrant/service-request-success.vue').default, name: 'payment-success' },
    {path: 'payment/failure', component: require('../views/migrant/service-request-failure.vue').default, name: 'payment-failure' },

    // {path: 'register', component: require('../views/migrant/register.vue').default, name: 'migrant-register' }, 
    // {path: 'register', component: require('../views/migrant/register.vue').default, name: 'migrant-register' }, 
        
])
<template>
    <div>
        <div class="flex my-3.5 pb-3">
                <label for="" class="w-2/6  min-w-sm uppercase my-auto text-sm font-bold text-c515151 tracking-widest ">{{ label }}</label>
                <div class="w-4/6">
                    <span v-if="!form" class="  " style="font-size: 16px"> {{text ? text : value}} </span>
                    <FormulateInput v-show="form" :readonly="readonly" :type="type" :value="value" :options="options" :validation="validation" :name="name"
                    input-class=" border border-cE1E1E1  text-lg py-3 px-4 outline-none w-full"
                     />
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        props: ['label', 'type', 'value', 'form', 'validation', 'name', 'options', 'readonly', 'text']
    }
</script>

<style lang="scss" scoped>

</style>
<template>
  <div class="absences-wrapper grid grid-cols-1 gap-4" v-if="migrant">
    <div class="" v-if="migrant.has_international_passport == false">
      <div class="w-full flex flex-col justify-center items-center rounded-lg bg-white px-8" style="min-height: 80vh">
                <box-icon name='error' size="lg" color="#DF3939"></box-icon>
            <h2 class="text-2xl font-extrabold"> Incomplete  Information </h2>
            <p class="my-8 text-c707070">
              To access this module, you have to add your Biometric Residence Permit
            </p>
            <div class="my-2 text-center">
            <router-link class="btn-primary py-5 block" :to="{name: 'migrant-documents'}"> Add BRP  </router-link>
            </div>
      </div>
    </div>
    <div v-else>
    <t-card variant="absencesPage" class="">
      <template v-slot:header>
        <div class="flex items-center lg:justify-between">
          <h2 class="w-7/12 md:w-9/12 text-xl font-bold">Travel History</h2>
          <t-select variant="firstSelect" placeholder="Travel History" :options="options" v-model="travelYear"></t-select>
        </div>
      </template>

      <div class="">
        <FormulateForm name="compliance" @submit="saveRecord" v-model="formData" class="sm:grid sm:grid-cols-2 gap-4 my-8">

        <div class="form-header col-span-2 md:col-span-1 flex items-center">
          <h3 v-if="loadingPage" class="bar-loading"></h3>
          <h3 v-else class="text-4xl font-bold" :class="(daysLeft < 80 ? 'text-red-400' : 'text-green-500')" >{{ daysLeft }} days left</h3>
          <svg xmlns="http://www.w3.org/2000/svg" class="ml-2 mt-1 w-10" height="28" width="19" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z" clip-rule="evenodd" />
          </svg>
        </div>

        <div class="compliance-btn my-4 col-span-2 md:col-span-1 flex justify-start md:justify-end">
          <t-button variant="cardTop" type="button" >ILR Compliance</t-button>
        </div>

        <div class="form-para col-span-2 my-4 flex justify-start">
          <p class="w-full lg:w-6/12">Please enter departure and entry dates to the UK as per the entry stamps on your passport</p>
        </div>

        
        <formulateInput 
          type="date" input-class="w-full outline-none bg-white" validation="required" class="abs-input" name="departure_date" label="Departure Date" placeholder="Departure date" />
        
        <formulateInput validation-name="Return Date" :validation="'required|after:'+formData.departure_date"  input-class="w-full outline-none bg-white" type="date" class="abs-input" name="return_date" label="Return Date" placeholder="Return date" />

        <formulateInput type="textarea" validation="required" input-class="w-full h-full outline-none bg-white" class="abs-input" name="reason"  placeholder="Reason for Leave"></formulateInput>
        <div class="">

        <label for="">Countries Visited: </label>
        <span v-for="country,id in addCountries" :key="'b-' + id" class="bg-gray-100 px-1 rounded-lg text-sm cursor-pointer mx-1 hover:bg-gray-50 transition"  @click="remove(id)"> {{country}} </span>
        
        <select v-model="tempCountry" class="abs-input bg-white" id="" @change="addCountry"> 
          <option :value="null"> Select Country </option>
          <option v-for="country,i in countries" :key="i" :value="country.name"> {{country.name }} </option>
        </select>
        </div>

        <div class="col-span-2 flex justify-end my-2">
          <button :disabled="processing" :class="processing ? 'btn-disabled' : 'btn-primary' "  type="submit" >{{processing ? 'Please Wait' : 'Save' }}</button>
        </div>
        </FormulateForm>
      </div>
      <div class="" v-if="loadingPage">
        <profile-skeleton />
      </div>
    <div class="card-body" v-else>
      <table-absence :headers="departureTable.tableHead" :data="departureTable.tableData">
             <template slot="row" slot-scope="props">
         
         <tr :class="[props.trClass, props.rowIndex % 2 === 0 ? ' ' : '']" >
           <td :class="props.tdClass"> {{props.row.departure_date | dateFormat }} </td>
           <td :class="props.tdClass"> {{ props.row.return_date  | dateFormat }} </td>
           <td :class="props.tdClass"> {{ props.row.days_absent }} </td>
           <td :class="props.tdClass"> {{ props.row.reason }} </td>
           <td :class="props.tdClass"> {{ props.row.countries_visited }} </td>
            </tr>
             </template>
      </table-absence>
    </div>
      <div class="empty-for-grid"></div>
    </t-card>
    </div>
    <div class="empty-for-grid"></div>
  </div>
 
</template>

<script>
import profileSkeleton from '../../partials/skeleton/profile-skeleton.vue';
import Countries from '../../assets/json/countries.json'
export default {
  components: { profileSkeleton },
  props: ['migrant'],
  data() {
    return {
      options: ["Year 2022"],
      travelYear: "",
      formData: {},
      countries: Countries,
      daysLeft: 180,
      loadingPage: true,
      processing: false,
      addCountries: [],
      passport: true,
      tempCountry: null,
      departureTable: {
        tableHead: [
          { value: "departure_date", text: "Departure" },
          { value: "return_date", text: "Return" },
          { value: "days_absent", text: "Days Absent" },
          { value: "reason", text: "Reason" },
          { value: "countries_visited", text: "Countries Visited" },
        ],
        tableData: [
         ]
      },
    };
  },
  methods: {
     remove(ix){
        this.addCountries.splice(ix,1)
    },
    addCountry(e){
      let country = e.target.value
      // console.log(country); 
      var exists = this.addCountries.some(element => {
                        return element == country;
                    })
        if(exists == false){
            this.addCountries.push(country)
        }
        this.tempCountry = null
    },
    saveRecord(){
      if(this.addCountries.length == 0 ){
        this.$toast.error('You have to supply the name of the country you visited')
        return false
      }
        this.processing = true
      this.formData.country = this.addCountries
      this.axios.post('migrant/compliance', this.formData).then(response => {
        this.processing = false
        this.departureTable.tableData.push(response.data.data.record)
        this.daysLeft = (response.data.data.remaining)
        // console.log(response.data.data.record);
           this.$toast.success( response.data.message , {timeout: 5000});
            this.$formulate.reset('compliance')
         
      }).catch((error) => {
        this.processing = false
          this.$toast.error( error.response.data.message , {timeout: 5000});
      })
    },
    getRecords(){
      this.axios.get('migrant/compliance').then( response => {
        this.loadingPage = false
        this.departureTable.tableData = response.data.data.records.data
        this.daysLeft = response.data.data.remaining
        this.passport = response.data.data.passport
      }).catch(err => {
        this.$toast.error(err.response.data.message, {timeout: 5000})
      })
    }
  },
  mounted(){
    this.$emit('pageTitle', 'Absences Calculator')
    this.getRecords()
  }
};
</script>

<style scoped>
.abs-input{
  @apply outline-none w-full py-4 border-b border-cCCCCCC
}
</style>

<template>
  <div class="mb-4">
    <t-card variant="articles">
      <template v-slot:header> 
        <div class="flex justify-center lg:justify-between">
          <h3>Articles</h3>
          <t-button variant="cardTop" class="hidden lg:block">See More</t-button>
        </div>
      </template>

      <div class="container grid grid-cols-1 gap-7">
        <p class="mt-5 text-base ">A selection of Tier 2 Compliance related articles for your reading pleasure.</p>

        <div class="post-wrapper grid grid-col-1 grid-cols-2  gap-8">
          <article class="article grid grid-cols-1 gap-5" v-for="article in articles" :key="article.id">
            <img class="w-full" :src="article.img" :alt="article.imgAlt">
            <h4 class="text-base  font-extrabold hover:opacity-75"><a :href="article.link"  target="_blank">{{article.title}}</a></h4>
            <p class="text-sm">{{article.body}}</p>
            <p>By <b>{{article.author}}</b>  | {{article.date}}</p>
          </article>
          <div></div>
        </div>
      </div>
    </t-card>
  </div>
</template>

<script>
export default {
  data(){
    return{
      articles: [
        {
          id: 1,
          img: require('../assets/images/articles/covid/covid.png'),
          imgAlt: 'Some alt text',
          title: 'Switching from Youth mobility to Skilled Worker Visa',
          body: 'Historically, the UK’s Home Office has limited the range of visas that can be switch to a work visa from within the UK...',
          author: 'Amar Ali',
          date: '01/31/2021',
          link: "https://immigrationlawyers-london.com/blog/switching-from-youth-mobility-to-skilled-worker-visa.php"
        },
        {
          id: 2,
          img: require('../assets/images/articles/citizenship/citizenship.png'),
          imgAlt: 'Some alt text',
          title: 'Switching From Tier 2 (ICT) To Skilled Worker Route',
          body: 'For migrants in the UK, one of the biggest bugbears of the immigration system is that it is not always possible to switch from one visa to another',
          author: 'Amar Ali',
          date: '01/31/2021',
          link: "https://immigrationlawyers-london.com/blog/switching-from-tier-2-ict-to-skilled-worker-route.php"
        },
        {
          id: 3,
          img: require('../assets/images/articles/eu/eu.png'),
          imgAlt: 'Some alt text',
          title: 'Differences between the Skilled Worker visa & other Tier 2 visa routes',
          body: 'When it comes to immigration law, change is a constant. The Home Office is constantly refreshing existing schemes and creating new ones',
          author: 'Amar Ali',
          date: '01/31/2021',
          link: "https://immigrationlawyers-london.com/blog/skilled-worker-vs-tier-two-sponsorship.php"
        },
        {
          id: 4,
          img: require('../assets/images/articles/facebook/facebook.png'),
          imgAlt: 'Some alt text',
          title: 'Switching from UK Tier 1 Entrepreneur Visa to Skilled Worker Visa',
          body: 'As we all know, change is constant, and the plans we have for today may be completely redundant tomorrow',
          author: 'Amar Ali',
          date: '01/31/2021',
          link: "https://immigrationlawyers-london.com/blog/switching-from-uk-tier-one-to-skilled-worker-visa.php"
        },
      ]
    }
  }
};
</script>

<style>
</style>
<template>
    <div>
        <navigation :links="links" />
            <div class="flex h-screen bg-gray-50 items-center justify-center">
            <p class="font-whytte text-center  text-6xl font-extrabold">
                This page has been <br> <br> suspended as it has been built before</p>

            </div>


        <home-hero title="We will get back to designing this homepage." intro="Watching events unfold in Cape Verde since 12 June 2020, date on which Venezuelan diplomat Alex Saab was unlawfully detained by Cape Verdean Law" />

        <home-cta intro="Register as Migrant or Company" hero="Manage your tier 2 compliance license / status with ease!" action="Get Started" route="pre-register" />

        <home-footer />   





    </div>
</template>

<script>
import Home_cta from '../partials/home_cta.vue'
import Home_hero from '../partials/home_hero.vue'
import Home_Footer from '../partials/home_footer.vue'
import navigation from '../components/navigation.vue'
    export default {
  components: { 'navigation' : navigation, 'home-hero' : Home_hero, 'home-cta' : Home_cta, 'home-footer':Home_Footer },
    data(){
        return {
            links: [],
        }
    }
    }
</script>

<style lang="scss" scoped>

</style>
<template>
    <div>
         <div class=" flex flex-col md:max-w-md mx-auto  md:h-screen lg:h-screen sm:py-12 py-12 justify-center">
         <FormulateForm
                        class="mx-10"
                        @submit="submitPasswordTokenRequest()"
                    >
                       <p class="text-text text-2xl">
                        {{ (next == true) ? 'Enter the five digit code sent to your email to reset your password'  : 'Enter your email to reset your password.' }}
                        </p>

                        <p class="text-red-400 mt-4" v-if="failureMsg"> {{ failureMsg}}</p>
                        <div  v-if="!next">
                                <FormulateInput
                                v-model="email"
                                type="email"
                                name="email"
                                label=""
                                input-class="py-2 mt-5 mb-2 outline-none text-text bg-white border-b-1 border-solid block w-full border-2 border-t-0 border-l-0 border-r-0 border-b-1 border-gray "
                                placeholder="Email"
                                validation="required|email"
                                
                                />
                                <FormulateInput
                                type="submit"
                               
                                :label="buttonText"
                                :disabled="processing"
                                :input-class="processing == true ? 'btn-reset-disabled' : 'btn-reset'"
                                />
                        </div>
                        <div v-else class="">
                            <v-otp-input
                            inputClasses="py-3 text-center mt-5 mb-2 w-5/6 mr-4 outline-none text-text bg-white border-b-1 bg-white border-solid border-2 border-t-0 border-l-0 border-r-0 border-b-1 font-extrabold border-gray otp-input text-2xl "
                            :numInputs="5"
                            separator="  "
                            :shouldAutoFocus="true"
                            @on-complete="handleOnComplete"
                            @on-change="handleOnChange"
                            />
                            <div class="mr-4">
                                <button type="button" @click="VerifyEmail()" :disabled="processing" :class=" processing ? 'btn-reset-disabled' : 'btn-reset'">
                                   Continue
                                </button>
                            </div>
                        </div>
                       
                        <p class="text-text mt-3">
                            Already have an account?  <router-link :to="{name: 'migrant-login'}" class="text-c1C6EE8"> Log in </router-link>
                        </p>
                        
                    </FormulateForm>
         </div>
    </div>
</template>

<script>
    import OtpInput from "@bachdgvn/vue-otp-input"
    export default {
        components : {
           'v-otp-input': OtpInput
        },
        data(){
            return{
                email: null,
                password: null,
                failureMsg: false,
                next: false,
                buttonText: 'Reset Password',
                processing: false,
                registered: false,
                token: ''
            }
        },
        methods : {
             submitPasswordTokenRequest(){
                 this.processing = true;
                 this.failureMsg = false;
                 this.axios.post('/password-recovery', {email: this.email}).then(() => {
                    this.next = true
                    this.processing = false;
                 }).catch(error => {
                     this.processing = false;
                     this.$toast.error(error.response.message, {timeout: 5000})
                     console.log(error);
                 })
            },
            VerifyEmail(){
                this.failureMsg = false
                this.axios.post('/password-recovery-check', {email: this.email, token: this.token}).then( () => {
                    // this.token = response.data.token;
                    // localStorage.setItem("apiToken", response.data.token );
                    this.$emit('verified', {email: this.email,  token: this.token})
                }).catch(()=>{
                    this.failureMsg = "Verification code is invalid or expired"
                })
            },
            handleOnComplete(value){
                this.token = value;
                this.VerifyEmail();
            },
            handleOnChange(){
                
            }
        }
    }
</script>

<style  scoped>

</style>